import React, { useEffect, useState } from 'react';
import {
  Calendar,
  defaultCalendarStrings,
  Toggle,
  DefaultButton,
  DirectionalHint,
  Callout,
  FocusTrapZone,
  Dropdown,
  IDropdownOption,
  DropdownMenuItemType,
  IDropdownStyles,
  Label,
  CommandBarButton,
  IIconProps,
} from '@fluentui/react';
import './Calendar.scss';
import Project from '../../system/projects/Project';
import { CalendarFilters } from '../../classes/Calendar/Calendar';
import { useBoolean } from '@fluentui/react-hooks';
import { useTranslation, Trans } from 'react-i18next';
import TimeTaxSupportSystemClient from '../../system';
import MessageCourier from '../../lib/MessageCourier';
import { ProjectSelector } from '../Common/ProjectSelector';
import { IProjectDescriptor } from '../../system/projects/ProjectDescriptor';
import { CalendarEvent } from '../../classes/Calendar/CalendarEvent';
import { User } from '../../system/User';
import { useSearchParams } from 'react-router-dom';
import { getLocalizedStrings } from '../../lib/LocalizedStringsForDatePicker';

interface ICalendarMenuProps {
  projects: IProjectDescriptor[] | undefined;
  onChangeFilters: (name: string, value: any) => Promise<void>;
  onChangeCalendar: (_date: Date) => void;
  filters: CalendarFilters;
  onChange24hs: (checked: boolean) => void;
  onChangeShowWeekends: (checked: boolean) => void;
  onChangeStartCalendar: (start: string) => void;
  value24hs: boolean;
  valueShowWeekends: boolean;
  valueStartCalendar: string;
  showConfigMenu?: boolean;
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  cleanFilters: () => void;
  user: User;
}

export interface ICalendarConfig {
  [index: string]: any;
  showWeekends: boolean;
  sinceHour: string;
  mode24hs: boolean;
}

export const SHOW_WEEKENDS = 'show_weekends';
export const SINCE_HOUR = 'since_hour';
export const MODE_24HS = 'mode_24hs';
export const LAST_NAVIGATION = 'last_navigation';
export const LAST_VIEW = 'last_view';

export function saveOnLocalStore(concept: string, value: any) {
  let calendarConfigStorage = localStorage.getItem('calendarConfig');
  let calendarConfig = {} as ICalendarConfig;

  if (calendarConfigStorage) {
    calendarConfig = JSON.parse(calendarConfigStorage);
    calendarConfig[concept] = value;
  } else {
    calendarConfig[concept] = value;
  }

  localStorage.setItem('calendarConfig', JSON.stringify(calendarConfig));
}

function getLastNavigationFromLocalStoreForInitializeFilter() {
  let calendarConfigStorage = localStorage.getItem('calendarConfig');
  let calendarConfig = {} as ICalendarConfig;
  if (calendarConfigStorage) {
    calendarConfig = JSON.parse(calendarConfigStorage);
    if (calendarConfig[LAST_NAVIGATION] != undefined) {
      return new Date(calendarConfig[LAST_NAVIGATION]);
    }
  }
  return new Date();
}

const CalendarMenu = (props: ICalendarMenuProps) => {
  const { t, i18n } = useTranslation();
  const [selectedDate, setSelectedDate] = React.useState<Date>(
      getLastNavigationFromLocalStoreForInitializeFilter()
  );
  const [showCalendar, { toggle: toggleShowCalendar, setFalse: hideCalendar }] = useBoolean(false);
  const TODOS_KEY = 'todos';

  const queryParams = new URLSearchParams(window.location.search);
  const activityId = queryParams.get('Actividad');

  const getEventData = async () => {
    if (activityId) {
      let _activity = await props.system.getActivitySystem().getActivity(activityId);
      let date = _activity.getStart();
      const dayOfWeek = date.getDay();
      if (dayOfWeek === 0 || dayOfWeek === 6) {
        props.onChangeShowWeekends(true);
        saveOnLocalStore(SHOW_WEEKENDS, true);
      }
      if (date.getHours() < 8) {
        props.onChange24hs(true);
        saveOnLocalStore(MODE_24HS, true);
      }
      if (_activity.isDeclined(props.user.getCollaborator())) {
        props.onChangeFilters(KEY_DECLINADAS, true);
      }
      if (!_activity.getActive()) {
        props.courier.messageError(t('La actividad no está activa'));
      }

      onSelectDate(date);
    }
  };

  useEffect(() => {
    getEventData();
  }, [activityId, showCalendar]);

  const onSelectDate = React.useCallback((date: Date, selectedDateRangeArray?: Date[] | undefined) => {
    setSelectedDate(date);
    saveOnLocalStore(LAST_NAVIGATION, date);
    props.onChangeCalendar(date);
  }, []);

  React.useEffect(() => {
    let calendarConfigStorage = localStorage.getItem('calendarConfig');

    let calendarConfig = {} as ICalendarConfig;

    if (calendarConfigStorage) {
      calendarConfig = JSON.parse(calendarConfigStorage);
      if (calendarConfig[MODE_24HS]) props.onChange24hs(calendarConfig[MODE_24HS]);
      if (calendarConfig[SHOW_WEEKENDS]) props.onChangeShowWeekends(calendarConfig[SHOW_WEEKENDS]);
      if (calendarConfig[SINCE_HOUR]) props.onChangeStartCalendar(calendarConfig[SINCE_HOUR]);
    }
  }, [props]);

  const optionHours = React.useMemo((): IDropdownOption[] => {
    let options: IDropdownOption[] = [];
    let hour = 0;
    while (hour <= 23) {
      options.push({
        key: `${hour.toString().padStart(2, '0')}:00`,
        text: `${hour.toString().padStart(2, '0')}:00`,
      });
      hour++;
    }
    return options;
  }, []);

  const KEY_OUTLOOK = 'verEventosOutlook';
  const KEY_ELIMINADAS = 'verEliminadas';
  const KEY_OCULTAS = 'verOcultas';
  const KEY_DECLINADAS = 'verDeclinadas';

  const optionEvents = React.useMemo((): IDropdownOption[] => {
    let options: IDropdownOption[] = [
      { key: KEY_OUTLOOK, text: 'Outlook' },
      { key: KEY_ELIMINADAS, text: t('Deleted') },
      { key: KEY_DECLINADAS, text: t('Declined') },
      { key: KEY_OCULTAS, text: t('Hidden') },
    ];
    return options;
  }, []);

  const buttonContainerRef = React.useRef<HTMLDivElement>(null);

  function onChangeShowEvents(option: any) {
    props.onChangeFilters(option.key, option.selected);
  }

  function onChangeStartCalendar(option: any) {
    props.onChangeStartCalendar(option.key);
    saveOnLocalStore(SINCE_HOUR, option.key);
  }

  const filtersActive = React.useMemo(() => {
    const ret: string[] = [];

    if (props.filters && props.filters.verEventosOutlook) {
      ret.push(KEY_OUTLOOK);
    }

    if (props.filters && props.filters.verOcultas) {
      ret.push(KEY_OCULTAS);
    }
    if (props.filters && props.filters.verEliminadas) {
      ret.push(KEY_ELIMINADAS);
    }
    if (props.filters && props.filters.verDeclinadas) {
      ret.push(KEY_DECLINADAS);
    }

    return ret;
  }, [props.filters]);

  function cleanFilters() {
    props.cleanFilters();
    setSelectedDate(new Date());
    setSelectedProjectId(TODOS_KEY);
    let selectedKeys: string[] = [];
    selectedKeys.push(KEY_OUTLOOK);
  }

  const ClearFilter: IIconProps = { iconName: 'ClearFilter' };

  const [selectedProjectId, setSelectedProjectId] = React.useState<string>(TODOS_KEY);
  return (
      <div className="menu-calendar">
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-lg2 ms-xl12 ms-xxl12 toggle-menu">
              <CommandBarButton
                  iconProps={ClearFilter}
                  className="clean-filters-calendar"
                  text={t('Limpiar filtros')}
                  onClick={cleanFilters}
                  styles={{ root: { height: 44, background: '#faf9f8' } }}
              />
            </div>

            <div className="ms-Grid-col toggle-menu ms-xxl12 ms-hiddenXxlUp">
              <div ref={buttonContainerRef} className="">
                <Trans>
                  <Label>{t('Go to')}</Label>
                </Trans>
                <DefaultButton
                    onClick={toggleShowCalendar}
                    text={!selectedDate ? t('Click for Calendar') : selectedDate.toLocaleDateString()}
                />
              </div>
              {showCalendar && (
                  <Callout
                      isBeakVisible={false}
                      gapSpace={0}
                      doNotLayer={false}
                      target={buttonContainerRef}
                      directionalHint={DirectionalHint.bottomLeftEdge}
                      onDismiss={hideCalendar}
                      setInitialFocus
                  >
                    <FocusTrapZone isClickableOutsideFocusTrap>
                      <Calendar
                          strings={getLocalizedStrings()}
                          onSelectDate={onSelectDate}
                          showMonthPickerAsOverlay
                          onDismiss={hideCalendar}
                          value={selectedDate}
                          highlightSelectedMonth
                          isDayPickerVisible
                          showGoToToday
                      />
                    </FocusTrapZone>
                  </Callout>
              )}
              {/* </div> */}
            </div>
            <div className="px-0 ms-Grid-col ms-lg3 ms-xl12 ms-xxl12 ms-hiddenXlDown">
              <Calendar
                  strings={getLocalizedStrings()}
                  showMonthPickerAsOverlay
                  highlightSelectedMonth
                  showGoToToday={false}
                  onSelectDate={onSelectDate}
                  value={selectedDate}
              />
            </div>

            <div className="ms-Grid-col ms-lg3 ms-xl12 ms-xxl12 toggle-menu">
              <Label>{t('My projects')}</Label>
              <ProjectSelector
                  system={props.system}
                  courier={props.courier}
                  onSelectProject={(idProject: string) => {
                    props.onChangeFilters('proyectoId', idProject ? idProject : TODOS_KEY);
                    setSelectedProjectId(idProject);
                  }}
                  selectedProjectId={selectedProjectId}
                  enableAll={true}
              />
            </div>

            <div className="ms-Grid-col ms-lg2 ms-xl12 ms-xxl12 toggle-menu">
              <Dropdown
                  multiSelect
                  // defaultSelectedKeys={[KEY_OUTLOOK]}
                  selectedKeys={filtersActive}
                  label={t('Show event')}
                  onChange={(e, option) => onChangeShowEvents(option)}
                  options={optionEvents}
              />
            </div>
            {props.showConfigMenu && (
                <>
                  <div className="ms-Grid-col ms-xxl12 toggle-menu ms-hiddenXlDown mt-2 ">
                    <hr></hr>
                  </div>

                  {/* <div className="ms-Grid-col ms-xxl12 toggle-menu ms-hiddenXlDown ">
                <Label>{t('Configuration')}</Label>
              </div> */}

                  <div className="ms-Grid-col ms-lg2 ms-xl12 ms-xxl12 toggle-menu">
                    <Toggle
                        label={t('All day mode')}
                        onChange={(ev, checked) => {
                          props.onChange24hs(!!checked);
                          saveOnLocalStore(MODE_24HS, checked);
                        }}
                        checked={props.value24hs}
                    />
                  </div>
                  <div className="ms-Grid-col ms-lg2 ms-xl12 ms-xxl12 toggle-menu">
                    <Toggle
                        label={t('Show weekends')}
                        onChange={(ev, checked) => {
                          props.onChangeShowWeekends(!!checked);
                          saveOnLocalStore(SHOW_WEEKENDS, checked);
                        }}
                        checked={props.valueShowWeekends}
                    />
                  </div>
                  <div className="ms-Grid-col ms-lg1 ms-xl12 ms-xxl12 toggle-menu">
                    <Dropdown
                        defaultSelectedKey={[props.valueStartCalendar]}
                        label={t('Start at')}
                        onChange={(e, option) => onChangeStartCalendar(option)}
                        options={optionHours}
                    />
                  </div>
                </>
            )}
          </div>
        </div>
      </div>
  );
};

export default CalendarMenu;
