import axios, { AxiosRequestConfig } from 'axios';
import Environment from './Environment';
import XhrRequestError from './XhrRequestError';
import XhrRequestCache from './XhrRequestCache';
import { getDeviceData } from './DeviceDetector';
import XhrErrorListener from './XhrErrorListener';
import { ILisCargo, IOrganizationNode } from '../Components/Collaborator/List/collaboratorCreate';
import { FieldValues } from 'react-hook-form';
import gererateUuid from '../common/uuid';
import { IList_cnih } from '../Components/Collaborator/CollaboratorView/updateCollaborator/InformacionGeneral';
import { IEstructuraOrganizacional } from '../Components/Collaborator/CollaboratorView/updateCollaborator/interface';

const progressKey = 'progress';

interface IProgressEvent {
  loaded: number;
  total: number;
  type: string;
}

export type UploadTracker = (loaded: number, total: number) => unknown;

interface IUnauthConfig {
  uploadTracker?: UploadTracker;
  abortController?: AbortController;
  headers?: { [index: string]: string };
}

class XhrRequestHandler {
  private listeners: XhrErrorListener[];

  constructor(private environment: Environment, private cache: XhrRequestCache) {
    this.listeners = [];
  }

  clearCache() {
    this.cache.clear();
  }

  subscribe(aListener: XhrErrorListener) {
    this.assertIsNotSubscribed(aListener);
    this.listeners.push(aListener);
  }

  private assertIsNotSubscribed(aListener: XhrErrorListener) {
    if (this.isSubscribed(aListener)) {
      throw new Error('The listener is already subscribed');
    }
  }

  private isSubscribed(aListener: XhrErrorListener) {
    return this.listeners.includes(aListener);
  }

  unsubscribe(aListener: XhrErrorListener) {
    this.listeners = this.listeners.filter((listener) => listener !== aListener);
  }

  /**
   * @throws {XhrRequestError}
   */
  async get<T>(url: string): Promise<T> {
    return this.cache.getValue(url, (url) => {
      return this.doThrowingRequestError<T>(url, async (fullUrl, config) => {
        return (await axios.get<T>(fullUrl, config)).data;
      });
    });
  }

  /**
   * @throws {XhrRequestError}
   */
  async post<T = unknown, S = unknown>(url: string, data?: S): Promise<T> {
    const requestBody = data === undefined ? {} : data;
    return this.doThrowingRequestError(
        url,
        async (fullUrl, config) => (await axios.post<T>(fullUrl, requestBody, config)).data
    );
  }

  /**
   * @throws {XhrRequestError}
   */
  async put<T, S>(url: string, data: S): Promise<T> {
    return this.doThrowingRequestError(
        url,
        async (fullUrl, config) => (await axios.put<T>(fullUrl, data, config)).data
    );
  }

  /**
   * @throws {XhrRequestError}
   */
  async patch<T = unknown, S = unknown>(url: string, data: S): Promise<T> {
    return this.doThrowingRequestError(
        url,
        async (fullUrl, config) => (await axios.patch<T>(fullUrl, data, config)).data
    );
  }

  /**
   * @throws {XhrRequestError}
   */
  async unauthenticatedPutForUpload<T, S>(
      url: string,
      data: S,
      { uploadTracker, abortController, headers }: IUnauthConfig
  ): Promise<T> {
    console.log(data);
    
    // Verificar si data es un Blob o File y tiene tamaño 0
    if ((data instanceof Blob || data instanceof File) && data.size === 0) {
      throw new XhrRequestError('EmptyFileUpload', 'El archivo subido está vacío');
    }

    return this.doThrowingRequestError(
        url,
        async (fullUrl) =>
            (
                await axios.put<T>(fullUrl, data, {
                  onUploadProgress: (event: IProgressEvent) => {
                    if (event.type !== progressKey || !uploadTracker) return;
                    uploadTracker(event.loaded, event.total);
                  },
                  signal: abortController && abortController.signal,
                  headers,
                })
            ).data
    );
  }

  /**
   * @throws {XhrRequestError}
   */
  delete<T = unknown>(url: string): Promise<T> {
    return this.doThrowingRequestError(
        url,
        async (fullUrl, config) => (await axios.delete<T>(fullUrl, config)).data
    );
  }

  navigateTo(url: string) {
    return this.openUrl(url, false);
  }

  open(url: string) {
    return this.openUrl(url, true);
  }

  /**
   * @throws {XhrRequestError}
   */
  private async doThrowingRequestError<T>(
      url: string,
      closure: (requestUrl: string, config?: AxiosRequestConfig) => Promise<T>
  ): Promise<T> {
    try {
      const requestUrl = this.requestUrl(url);
      const defaultConfig: AxiosRequestConfig = { headers: this.defaultHeaders(), withCredentials: true };

      return await closure(requestUrl, defaultConfig);
    } catch (error) {
      const processedError = XhrRequestError.fromUnknown(error);
      this.listeners.forEach((listener) => listener.receiveError(processedError));
      throw processedError;
    }
  }

  requestUrl(url: string) {
    return this.isRelative(url) ? this.environment.apiUrl(url) : url;
  }

  private isRelative(url: string) {
    return !url.startsWith('http');
  }

  private defaultHeaders() {
    const headers: { [index: string]: string } = {};
    headers['X-Device-Info'] = getDeviceData();
    headers['Time-Zone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return headers;
  }

  private openUrl(url: string, newPage: boolean) {
    const destination = encodeURI(this.requestUrl(url));
    if (newPage) {
      window.open(destination, '_blank');
    } else {
      window.location.assign(destination);
    }
  }

  async newColaborator (date:FieldValues,id:string) {
    const datos = {
			data: date,
			uuid:id
		}
    let url = '/loginDVM/createUser';
    const fullUrl = this.environment.apiUrl(url);
    const { data } = await axios.post(fullUrl,datos);
    return data;
  }

  async organizationNode(norNivel?: string, norPadre?: string): Promise<IOrganizationNode[]>  {
    let url = '/loginDVM/search_organigrama';
    if (norNivel && norPadre) {
      url += `?nor_padre=${norPadre}&nor_nivel=${norNivel}`;
    } else if (norNivel) {
      url += `?nor_nivel=${norNivel}`;
    } else if (norPadre) {
      url += `?nor_padre=${norPadre}`;
    }
    
    const fullUrl = this.environment.apiUrl(url);
    const { data } = await axios.get<IOrganizationNode[]>(fullUrl);
    return data;
  }

  async listCargo(): Promise<ILisCargo[]>{
    const fullUrl = this.environment.apiUrl("/loginDVM/list_cargo");
    const {data} = await axios.get<ILisCargo[]>(fullUrl);
    return data;
  }

  async ListProyectoEnlace(email: string){
    let url = `/loginDVM/proyecto_enlace/${email}`;
    const fullUrl = this.environment.apiUrl(url);
    const {data} = await axios.get(fullUrl);
    return data;
  }

  async DeleteProyectforId(id: string){
    let url = `/loginDVM/delete_cnih/${id}`;
    const datos = {
      uuid:gererateUuid()
    }
    const fullUrl = this.environment.apiUrl(url);
    const {data} = await axios.post(fullUrl,datos);
    return data;
  }

  async modificarOneProyectoEnlace(datos:IList_cnih){
    const datosProyecto = {
      data: {
        activo:datos.cnih_activo,
        id: datos.cnih_id,
        participacion: datos.cnih_porcentaje_excento,
        vigencia: datos.cnih_fecha_alta,
        fechafin:datos.cnih_fecha_baja
      },
      uuid:gererateUuid()
    }
    const fullUrl = this.environment.apiUrl("/loginDVM/update/cnih");
    const {data} = await axios.post(fullUrl,datosProyecto);
    return data;
  }

  async CrearOneProyectoEnlace(datos:IList_cnih,email:string){
    const datosProyecto = {
      data: {
        email: email,
        participacion: datos.cnih_porcentaje_excento,
        vigencia: datos.cnih_fecha_alta,
        fechafin:datos.cnih_fecha_baja
      },
      uuid:gererateUuid()
    }
    const fullUrl = this.environment.apiUrl("/loginDVM/create/cnih");
    const {data} = await axios.post(fullUrl,datosProyecto);
    return data;
  }

  async CreateEstructuraOranizacional(datos:IEstructuraOrganizacional){
    try {
      const datosRegular = {
        data:datos,
        uuid:gererateUuid()
      };
      const fullUrl = this.environment.apiUrl("/loginDVM/create/estructura_oraganizacional");
      const {data} = await axios.post(fullUrl,datosRegular);
      return data
    } catch (error) {
      console.log(error)
    }
  }

  async UpdateEstructuraOranizacional(datos:IEstructuraOrganizacional){
    try {
      if(datos.carcol_id === undefined)return;
      const datosRegular = {
        data:datos,
        uuid:gererateUuid()
      };
      console.log(datosRegular)
      const fullUrl = this.environment.apiUrl("/loginDVM/estructura_oraganizacional/update");
      const {data} = await axios.put(fullUrl,datosRegular);
      return data
    } catch (error) {
      console.log(error)
    }
  }

  async ListEstructuraOrganizacional(id:string){
    try {
      if(!id)return;
      const url = `/loginDVM/estructura_oraganizacional/${id}`;
      const datosRegular = {uuid:gererateUuid()};
      const fullUrl = this.environment.apiUrl(url);
      const {data} = await axios.post(fullUrl,datosRegular);
      return data.data;
    } catch (error) {
      console.log(error)
      return [];
    }
  }

  async DeleteEstructuraOrganizacional(id:string){
    try {
      const url = `/loginDVM/deleteE/estructura_oraganizacional/${id}`;
      const datosRegular = {uuid:gererateUuid()};
      const fullUrl = this.environment.apiUrl(url);
      const {data} = await axios.post(fullUrl,datosRegular);
      return data;
    } catch (error) {
      console.log(error)
    }
  }

}

export default XhrRequestHandler;
export { XhrRequestError };

export interface QueryParam {
  name: string;
  value?: string | boolean | number;
}

export const getQueryParamsExpression = (params?: QueryParam[]): string => {
  if (!params) return '';

  let query = '';
  let separator = '?';
  params.forEach((param) => {
    if (
        param.name.length > 0 &&
        ((typeof param.value == 'string' && param.value.length > 0) ||
            typeof param.value == 'boolean' ||
            typeof param.value == 'number')
    ) {
      if (typeof param.value == 'boolean') param.value = param.value ? '1' : '0';
      query = `${query}${separator}${param.name}=${encodeURIComponent(param.value)}`;
      separator = '&';
    }
  });
  return query;
};

export interface StatusResponseJSON<T> {
  data: T;
  statusCode: number;
}
