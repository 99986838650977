import { ComboBox, DatePicker, Icon, TextField, Toggle, TooltipHost } from "@fluentui/react";
import { DateCustom } from "../../../../lib/DateCustom";
import {  spanishDatePickerStrings } from "../collaboratorCreateView"
import { useEffect, useState } from "react";
import formatearFecha from "../../../../common/formatDate";
import { FieldErrors, FieldValues, UseFormRegister, UseFormSetValue, UseFormWatch, ValidationRule } from "react-hook-form";
import TimeTaxSupportSystemClient from "../../../../system";
import { convertStringToDate } from "../../../../common/custom-hooks/TodayDate";
import { OrganizationChart } from "./EstructuraOrganizacional";

interface Cargo {
  cargo_activo: boolean;
  cargo_id: string;
  cargo_nombre: string;
}

interface CargoColaborador {
  carcol_activo: boolean;
  carcol_cargoid: string;
  carcol_colid: string;
  carcol_fecha_desde: string;
  carcol_fecha_hasta: string | undefined;
  carcol_id: string;
  carcol_norid: string;
  cargo: Cargo;
}

export interface IList_cnih{
  cnih_activo: string | undefined;
  cnih_id: string;
  cnih_fecha_alta:string | undefined;
  cnih_fecha_baja:string | undefined;
  cnih_porcentaje_excento:string | undefined;
}

export interface CargoColaboradorDate {
  cargoColaborador: CargoColaborador;
  col_actividad_facturable: boolean;
  col_activo: boolean;
  col_apellido: string;
  col_baja_motivo: string;
  col_documento_numero: string;
  col_email: string;
  col_fecha_alta: string | undefined;
  col_fecha_baja: string | undefined;
  col_fecha_nacimiento: string;
  col_id: string;
  col_id_sap: string;
  col_imagen_url: string;
  col_iniciales: string;
  col_nombre: string;
  col_telefono_movil: string;
  col_tipo_documento: string;
  us_id:string;
  us_perid:string;
  cnih_fecha_alta: string;
  cnih_fecha_baja: string | null;
  cnih_porcentaje_excento: string;
  List_cnih:IList_cnih[];
}


interface IUpdateTextField{
  setValue:UseFormSetValue<FieldValues>;
  name:string;
  register: UseFormRegister<FieldValues>;
  placeholder:string;
  label:string;
  type:string;
  width:string;
  height:string;
  minLength?:number | undefined;
  pattern?:ValidationRule<RegExp> | undefined;
  maxLength?:number | undefined;
  valorDefault?:string | undefined;
  watch?: UseFormWatch<FieldValues> | undefined;
  disable?:boolean;
}

export interface IUpdateInformacionGeneral{
  register: UseFormRegister<FieldValues>;
  errors:FieldErrors<FieldValues>;
  setValue:UseFormSetValue<FieldValues>;
  watch: UseFormWatch<FieldValues>
  system?:TimeTaxSupportSystemClient | undefined;
  datosColaborador?:CargoColaboradorDate;
  nodoNivel?:OrganizationChart | undefined;
  setSelecusuariosG?:(data:ITipoUser | undefined)=>void;
  selecusuariosG?:ITipoUser;
}

export interface ITipoUser{
  key: string;
  text:string;
}

function InformacionGeneral({errors,register,setValue,watch,system,datosColaborador,setSelecusuariosG}:IUpdateInformacionGeneral) {

  const[isActivo,setIsActivo] = useState<boolean>(true);
  const[valueDefaultDate,setValueDefaultDate] = useState<Date | undefined >(new Date());
  const[dateIngreso,setDateIngreso] = useState<Date | undefined>(new Date());
  const [selecusuariosTipo,setSelecusuariosTipo] = useState<ITipoUser | undefined >(undefined);
  const tipoUsersUsperid = [{ key: "00000005", text: "User" }, {key:"00000006",text:"Power User"},{key:"00000007",text:"Admin User"},{key:"00000010",text:"Directivo"} ]

  const handleDateChangeIngreso = (date,name:string) => {
    if(!date){
      setValue(name,"");
      return
    };
    const ddat = formatearFecha(date)
    setDateIngreso(date)
    setValue(name,ddat);
  };

  const handleDateChangeBaja = (date,name:string) => {
    if(!date){
      setValue(name,"");
      return
    };
    const ddat = formatearFecha(date)
    setValueDefaultDate(date)
    setValue(name,ddat);
  };

  const handlerActivo = ()=>{
    setIsActivo(!isActivo);
    if(isActivo === true){
      setValueDefaultDate(new Date());
      const ddat = formatearFecha(new Date())
      setValue("fechabaja",ddat);
    }else{
      setValueDefaultDate(undefined);
      setValue("fechabaja","");
    }
  };

  const userSlections = (option)=>{
    setValue("selecusuarios",option);
    setSelecusuariosTipo(option);
    if(!setSelecusuariosG)return;
    setSelecusuariosG(option)
  }

  useEffect(()=>{
    if (datosColaborador?.col_fecha_alta) {
    const convert = convertStringToDate(datosColaborador?.col_fecha_alta);
      setDateIngreso(convert);
     const DateConvert = invertDate(datosColaborador?.col_fecha_alta)
      setValue("fechaingreso",DateConvert);
    }

    register("fechabaja",{required:!isActivo});
    register("dni",{required:false});
    register("nombre",{required:isActivo});
    register("apellido",{required:isActivo});
    register("email",{required:isActivo});
    setValue("deshabilitado",!isActivo);
  },[isActivo]);

  useEffect(() => {
      register("selecusuarios",{required:true});
      register("fechaingreso",{required:true});
      register("deshabilitado",{value:false});
    if(datosColaborador?.col_activo === false){
      setIsActivo(false);
      if(datosColaborador?.col_fecha_baja){
        const respons=convertStringToDate(datosColaborador?.col_fecha_baja);
        setValueDefaultDate(respons);
        setValue("fechabaja",datosColaborador?.col_fecha_baja);
      }
    }
    if (datosColaborador?.us_perid ) {
      const userTipe = tipoUsersUsperid.find(user => user.key === datosColaborador?.us_perid);
      if(!userTipe)return;
      if(!setSelecusuariosG)return;
      setSelecusuariosG(userTipe);
     setValue("selecusuarios", userTipe);
     console.log(userTipe)
     setSelecusuariosTipo(userTipe);
    }
  },[])
    
  return (
    <div className="form_informacion_general">
      <p className="titulo_form_create">
        <Icon iconName="ContactCard" style={{fontSize:"30px"}} />
        Información general.
      </p>
      <div className="form_informacion_general_cuerpo">
      <span style={{display:"flex", flexDirection:"row",marginBottom:"-40px"}}>
      <UpdateTextField key="0010" width="158px" height="14px" label="Código SAP" valorDefault={datosColaborador?.col_id_sap}
        placeholder="Escriba el código SAP" type="text" name="codigosap" register={register}  setValue={setValue} disable={true} />
      <Toggle label={isActivo? "Activo" : "deshabilitado"} onClick={()=>handlerActivo()} checked={isActivo} />
      </span>
       {errors?.codigosap?.type === "required" && <span className="error_stilos">El código SAP no puede ser vacío.</span>}
       {errors?.codigosap?.type === "pattern" && <span className="error_stilos">Este campo solo permite números.</span>}

        <UpdateTextField key="0020"  width="158px" height="14px" label="Documento" valorDefault={datosColaborador?.col_documento_numero} disable={!isActivo}
         placeholder="Escriba el documento"  type="text" name="dni" register={register}  maxLength={13}  pattern={/^\d+$/} setValue={setValue} />
        {errors?.dni?.type === "required" && <span className="error_stilos">El documento no puede ser vacío.</span>}
        {errors?.dni?.type === "maxLength" && <span className="error_stilos">El documento no debe contener mas de 13 números.</span>}
       {errors?.dni?.type === "pattern" && <span className="error_stilos">Este campo solo permite números.</span>}

        <UpdateTextField key="0030"  width="302px" height="14px" label="Nombre" valorDefault={datosColaborador?.col_nombre}
         placeholder="Escriba el nombre del colaborador" disable={!isActivo}
         type="text" name="nombre" register={register} pattern={/^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]+(?: [a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]+)*$/} maxLength={20} setValue={setValue}/>
        {errors?.nombre?.type === "required" && <span className="error_stilos">El nombre del colaborador no puede ser vacío.</span>}
        {errors?.nombre?.type === "pattern" && <span className="error_stilos">El nombre no debe contener números.</span>}

        <UpdateTextField key="0040"  width="302px" height="14px" label="Apellidos" valorDefault={datosColaborador?.col_apellido}
        placeholder="Escriba los apellidos del colaborador" type="text" name="apellido" register={register} disable={!isActivo}
         pattern={/^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]+(?: [a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]+)*$/} maxLength={20} setValue={setValue}/>
        {errors?.apellido?.type === "required" && <span className="error_stilos">El apellido no puede ser vacío.</span>}
        {errors?.apellido?.type === "pattern" && <span className="error_stilos">El apellido no debe contener números.</span>}

        <UpdateTextField key="0050" disable={!isActivo} valorDefault={datosColaborador?.col_email} width="302px" height="14px" label="Email" placeholder="Escriba el email del colaborador" 
        type="text" name="email" register={register} pattern={/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/} maxLength={50} setValue={setValue}  />
        {errors?.email?.type === "required" && <span className="error_stilos" >El email no puede ser vacío.</span>}
        {errors?.email?.type === "pattern" && <span className="error_stilos" >Ingresa un email válido.</span>}
        {errors?.email?.type === "deps" && <span className="error_stilos">{errors?.email?.message}</span>}

        <div style={{display:"flex", flexDirection:"column"}}>
             <span className="update_date_derecho_form">
              <span>
              <DatePicker strings={spanishDatePickerStrings} value={dateIngreso}  allowTextInput formatDate={DateCustom.formatDateForDatePicker} label="Fecha de Ingreso" className="label_reusable" 
              placeholder="Seleccione la fecha de ingreso" disabled={!isActivo}
              styles={{root:{width:"200px"}}}  onSelectDate={ (date)=>handleDateChangeIngreso(date,"fechaingreso")}/>
              {errors.fechaingreso && <span className="error_stilos_practicante">La fecha de ingreso no puede ser vacío.</span>}
              </span>
              <span>
              <DatePicker  strings={spanishDatePickerStrings} allowTextInput formatDate={DateCustom.formatDateForDatePicker} label="Fecha de Baja" className="label_reusable" 
              placeholder="Seleccione la fecha de baja" disabled={isActivo? true:datosColaborador?.col_fecha_baja?true:false} value={valueDefaultDate} minDate={datosColaborador?.col_fecha_alta? convertStringToDate(datosColaborador?.col_fecha_alta): undefined}
              styles={{root:{width:"200px"}}}  onSelectDate={ (date)=>handleDateChangeBaja(date,"fechabaja")}/>
              {errors.fechabaja && <span className="error_stilos_practicante">La fecha de baja no puede ser vacío.</span>}
              </span>
             </span>
             <span className="date_derecho_form">
              <TooltipHost content={"Seleccione el tipo de usuario"}>
              <ComboBox
              className="label_reusable" placeholder="Seleccione el tipo de usuario."
              label="Seleccione el tipo de usuario" options={tipoUsersUsperid}
              onChange={(event,option)=>userSlections(option) }
               text={selecusuariosTipo?.text}
               disabled={!isActivo}
              />
              </TooltipHost>
              {errors.selecusuarios && <span className="error_stilos_practicante" >El tipo de usuario no puede ser vacío.</span>}
              </span>
        </div>
      </div>
    </div>
  );
}

function UpdateTextField({pattern,minLength,maxLength,setValue,name,register,height,label,width,placeholder,type,valorDefault,watch,disable=false}:IUpdateTextField){
  useEffect(()=>{
   register(name,{required:true,minLength,maxLength,pattern,value:valorDefault});
   setValue(name,valorDefault)
  },[]);
  return(
    <span className="input_reusable">
      <TextField
       styles={{root:{width:width, height:height}}}
       placeholder={placeholder}
       label={label}
       type={type}
       onChange={(event,newvalue)=>setValue(name,newvalue)}
       defaultValue={valorDefault}
       disabled = {disable}
      />
    </span>
  )
}

export default InformacionGeneral;

 export function invertDate(date: string): string {
  const [day, month, year] = date.split('/');

  return `${year}/${month}/${day}`;
}