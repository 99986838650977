import React from 'react';
import { ProjectConfigurationDistribution } from './ProjectConfigurationDistribution';
import { ProjectConfigurationBasicData } from './ProjectConfigurationBasicData';
import { ProjectConfigurationTeam } from './ProjectConfigurationTeam';
import TimeTaxSupportSystemClient from '../../../system';
import MessageCourier from '../../../lib/MessageCourier';
import './ProjectConfiguration.css';
import { FormContextProject } from '../../../system/projects/ProjectsSystem';
import ProjectConfigurationForm from './ProjectConfigurationForm';
import CustomInputIfc from '../../../lib/FormValidator/Form/CustomInputIfc';
import { ProjectConfigurationDocs } from './ProjectConfigurationDocs';
import CollaboratorTeam from '../../../system/collaborators/collaborator_team/CollaboratorTeam';
import { ProjectCompanyInDistribution } from '../../../system/projects/IProjectCompanyInDistribution';
import { User } from '../../../system/User';

interface IProjectConfigurationView {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  contextForm: FormContextProject;
  projectForm: ProjectConfigurationForm;
  onBlurCustomInput: (customInput: CustomInputIfc) => void;
  user: User;
}

export const ProjectConfigurationView = (props: IProjectConfigurationView) => {
  const [billable, setBillable] = React.useState<boolean>(props.projectForm.getBillableInput().getValue());
  const [projectTeam, setProjectTeam] = React.useState<CollaboratorTeam[]>(
      props.projectForm.getProjectTeamForm().getInputProjectTeamItems().getValue()
  );
  const [projectDistribution, setProjectDistribution] = React.useState<ProjectCompanyInDistribution[]>([]);

  const formProjectDistribution = React.useMemo(
      () => props.projectForm.getCompanyDistributionForm(),
      [props.projectForm]
  );

  React.useEffect(() => {
    setBillable(props.projectForm.getBillableInput().getValue());
    setProjectTeam(props.projectForm.getProjectTeamForm().getInputProjectTeamItems().getValue());
  }, [props.projectForm]);

  React.useEffect(() => {
    setProjectDistribution(formProjectDistribution.getInputDistribucionFacturableItems().getActive());
  }, [formProjectDistribution]);

  const onChangeBillable = (checked: boolean) => {
    setBillable(checked);
    setProjectDistribution([]);
    props.projectForm.getCompanyDistributionForm().resetAllCompanies();
  };

  const onChangeProjectTeam = (team: CollaboratorTeam[]) => {
    setProjectTeam(team);
  };

  return (
      <div className="container-config-project">
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row project-config-data-row">
            <div className="ms-Grid-col ms-lg12 ms-xl6">
              <ProjectConfigurationBasicData
                  system={props.system}
                  courier={props.courier}
                  organizationChartNodes={props.contextForm ? props.contextForm.organizationChartNodes : []}
                  collaboratorsList={props.contextForm ? props.contextForm.collaboratorList : []}
                  formProject={props.projectForm}
                  onBlurCustomInput={props.onBlurCustomInput}
                  onChangeBillable={onChangeBillable}
                  onChangeProjectTeam={onChangeProjectTeam}
              />
            </div>
            <div className="ms-Grid-col ms-lg12 ms-xl6 mt-2-in-down-xl">
              <ProjectConfigurationDistribution
                  system={props.system}
                  courier={props.courier}
                  companyList={props.contextForm ? props.contextForm.companyList : []}
                  formProjectDistribution={formProjectDistribution}
                  formProject={props.projectForm}
                  onBlurCustomInput={props.onBlurCustomInput}
                  billable={billable}
                  projectDistribution={projectDistribution}
                  onChangeDistribution={(items) => setProjectDistribution(items)}
                  user={props.user}
              />
            </div>
          </div>
          <div className="ms-Grid-row mt-2 pb-30px">
            <div className="ms-Grid-col ms-lg12 ms-xl6">
              <ProjectConfigurationTeam
                  system={props.system}
                  courier={props.courier}
                  formProject={props.projectForm}
                  formProjectTeam={props.projectForm.getProjectTeamForm()}
                  collaboratorsList={props.contextForm ? props.contextForm.collaboratorList : []}
                  onBlurCustomInput={props.onBlurCustomInput}
                  onChangeProjectTeam={onChangeProjectTeam}
                  projectTeam={projectTeam}
              />
            </div>
            <div className="ms-Grid-col ms-lg12 ms-xl6">
              <ProjectConfigurationDocs
                  system={props.system}
                  courier={props.courier}
                  formProject={props.projectForm}
                  documentControlForm={props.projectForm.getProjectDocumentControlForm()}
                  onBlurCustomInput={props.onBlurCustomInput}
                  documentTypeList={props.contextForm ? props.contextForm.documentTypeList : []}
                  collaboratorsInTeam={projectTeam}
              />
            </div>
          </div>
        </div>
      </div>
  );
};
