import { IDropdownOption } from '@fluentui/react';
export const OPERATOR_CONTAINS = 'contains';
export const OPERATOR_GREATERTHAN = 'greaterThan';
export const OPERATOR_EQUAL = 'eq';
export const OPERATOR_BEFOREORON = 'beforeOrOn';
export const OPERATOR_BEFORE = 'before';
export const OPERATOR_AFTERORON = 'afterOrOn';
export const OPERATOR_AFTER = 'after';

export const TYPE_INPUT = 'INPUT';
export const TYPE_CHECKBOX = 'CHECKBOX';
export const TYPE_BUTTON = 'BUTTON';
export const TYPE_DATE = 'DATE';
export const TYPE_DATETIME = 'DATETIME';
export const TYPE_DROPDOWN = 'DROPDOWN';
export const TYPE_COMBOBOX = 'COMBOBOX';

export enum OPERATORS {
  CONTAINS = 'contains',
  GREATERTHAN = 'get',
  EQUAL = 'eq',
  BEFOREORON = 'beforeOrOn',
  BEFORE = 'before',
  AFTERORON = 'afterOrOn',
  AFTER = 'after',
}

export enum TYPES {
  INPUT = 'INPUT',
  CHECKBOX = 'CHECKBOX',
  BUTTON = 'BUTTON',
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  DROPDOWN = 'DROPDOWN',
  COMBOBOX = 'COMBOBOX',
}

export const paginationComponentOptions = {
  rowsPerPageText: 'Resultados por página',
  rangeSeparatorText: 'de',
  selectAllRowsItem: true,
  selectAllRowsItemText: 'Todos',
};

const getFilterUrlFromFilterValues = (filters: any, filtersConfig: any) => {
  if (Object.entries(filters).length === 0) return '';

  let url = '';
  let i = 0;
  for (let prop in filters) {
    if (filters[prop]) {
      let operatorItem = filtersConfig.find((e: any) => e.name === prop);
      let operator = operatorItem ? operatorItem.operator : OPERATOR_CONTAINS;
      url += `&filter[${i}][name]=${prop}&filter[${i}][value]=${filters[prop]}&filter[${i}][operator]=${operator}`;
      i++;
    }
  }

  return url;
};

const getSortUrl = (sortOrder: any) => {
  let url = '';
  let _k = Object.keys(sortOrder);
  if (sortOrder[_k[0]]) {
    url = `&sort[0][id]=${_k[0]}&sort[0][direction]=${sortOrder[_k[0]]}`;
  }
  return url;
};

export const getDatagridUrlParams = (
    page: number,
    limit: number,
    filters: any,
    filtersConfig: any,
    order: any
) => {
  let skip = (page - 1) * limit;

  let params = `?skip=${skip}&limit=${limit}`;

  params += getFilterUrlFromFilterValues(filters, filtersConfig);
  params += getSortUrl(order);

  return params;
};

export const formatNumberToLocale = (number: number) => {
  const separadorMiles = '.';
  const separadorDecimales = ',';
  const localesConPunto = ['en-US', 'es-AR', 'es-CL', 'es-CO', 'es-MX', 'es-PE', 'es-VE'];

  const localUsuario = navigator.language;
  const usaPunto = localesConPunto.includes(localUsuario);

  const opciones = {
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  if (usaPunto) {
    opciones.minimumFractionDigits = 2;
    opciones.maximumFractionDigits = 2;
    return number.toLocaleString('en-US', opciones).replace(',', separadorDecimales);
  } else {
    opciones.minimumFractionDigits = 2;
    opciones.maximumFractionDigits = 2;
    return number.toLocaleString('es-ES', opciones).replace('.', separadorMiles);
  }
};

export interface DropdownIfc {
  label: string;
  value: string;
  id?: string;
}

export const initializeColumn = (name: string, prop: string, grow: number) => {
  let _ret = {
    name: name,
    selector: (row: any) => `${row[prop]}`,
    sortable: true,
    sortField: prop,
    filterable: true,
    grow: grow,
  };

  return _ret;
};

export const formatStartingSelection = (key: string, array: IDropdownOption<any>[]) => {
  if (!key) return '';

  const list = array.find((option) => option.key === key);
  return list ? list.text : '';
};

export const initializeColumnWithFormat = (
    name: string,
    prop: string,
    formatFn: (row: any) => string,
    grow: number
) => {
  let _ret = {
    name: name,
    selector: (row: any) => `${row[prop]}`,
    sortable: true,
    sortField: prop,
    filterable: true,
    format: (row: any) => formatFn(row),
    grow: grow,
  };

  return _ret;
};

export const InitializeFilter = {
  Input: (operator: string, name: string, placeholder: string, grow?: number) => {
    return {
      type: TYPES.INPUT,
      operator: operator,
      grow: grow,
      placeholder: placeholder,
      name: name,
    };
  },
  Dropdown: (
      operator: string,
      name: string,
      placeholder: string,
      contextForDropdown: any[],
      grow?: number
  ) => {
    return {
      type: TYPES.DROPDOWN,
      operator: operator,
      grow: grow,
      placeholder: placeholder,
      name: name,
      contextForDropdown: contextForDropdown,
    };
  },
  CheckBox: (operator: string, name: string, label: string, grow?: number) => {
    return {
      type: TYPES.CHECKBOX,
      operator: operator,
      grow: grow,
      name: name,
      labelForCheckBox: label,
      checkBoxValue: '1',
    };
  },
  DateTime: (operator: string, name: string, placeholder: string, grow?: number) => {
    return {
      type: TYPES.DATETIME,
      operator: operator,
      grow: grow,
      placeholder: placeholder,
      name: name,
    };
  },
};

export const prepareAppSelector = (_obj: any, key: string) => {
  if (_obj && _obj[key]) return _obj[key];
  else return {};
};

//Unicamente se usa en activity list.
export const timeSince = (date: string | undefined) => {
  if (date) {
    let _hoy: any = new Date();
    var dateSplit: any = date.split(' ');
    var dateParts: any = dateSplit[0].split('/');
    var hourParts: any = dateSplit[1].split(':');

    var dateObject: any = new Date(
        +dateParts[2],
        dateParts[1] - 1,
        +dateParts[0],
        hourParts[0],
        hourParts[1]
    );

    var seconds = Math.floor((_hoy - dateObject) / 1000);
    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) > 1
          ? `Cargada hace ${Math.floor(interval)} años`
          : `Cargada hace ${Math.floor(interval)} año`;
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) > 1
          ? `Cargada hace ${Math.floor(interval)} meses`
          : `Cargada hace ${Math.floor(interval)} mes`;
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) > 1
          ? `Cargada hace ${Math.floor(interval)} días`
          : `Cargada hace ${Math.floor(interval)} día`;
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) > 1
          ? `Cargada hace ${Math.floor(interval)} horas`
          : `Cargada hace ${Math.floor(interval)} hora`;
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) > 1
          ? `Cargada hace ${Math.floor(interval)} minutos`
          : `Cargada hace ${Math.floor(interval)} minuto`;
    }
  }
  return '';
};

export const GRID_MODE = 'gridMode';
export const CARD_MODE = 'cardMode';
