import React, { useEffect, useState } from 'react';
// import { Row, Col, Input, Button } from 'reactstrap';
import {
  TYPE_INPUT,
  TYPE_CHECKBOX,
  TYPE_DATETIME,
  TYPE_DROPDOWN,
  DropdownIfc,
  TYPE_DATE,
  TYPE_BUTTON,
  TYPES,
} from '../../../common/list-utils';
// import Datetime from 'react-datetime';
import { DateTime } from 'luxon';
import { ListColumnFilter } from '../list-entities/ListColumnFilter';
import {
  DatePicker,
  DefaultButton,
  Dropdown,
  IDropdownOption,
  ITextFieldStyles,
  IDropdownStyles,
  TextField,
  Toggle,
  CommandBarButton,
  Text,
  IIconProps,
  IStackStyles,
  IDatePickerStyles,
  ComboBox,
  IComboBox,
  IComboBoxOption,
} from '@fluentui/react';
import './DetailsListView.css';
import moment from 'moment';

import 'moment/locale/es';
import { DateCustom } from '../../../lib/DateCustom';
import OrganizationChartSystem from '../../../system/organization_chart/OrganizationChartSystem';
import { OrganizationChartDropdown } from '../OrganizationChartDropdown';
import { OrganizationChartNode } from '../../../system/organization_chart/OrganizationChartNode';
import i18next, { t } from 'i18next';
import { getLocalizedStrings } from '../../../lib/LocalizedStringsForDatePicker';
import ComboBoxSearch from '../ComboBoxSearch';

export interface FiltersConfigList {
  type: string;
  name: string;
  operator: string;
  placeholder?: string;
  grow?: number;
  contextForDropdown?: IDropdownOption<any>[];
  checkBoxValue?: string;
  labelForCheckBox?: string;
  customFilter?: any;
}

interface FilterWrapperProps {
  filtersConfigList: ListColumnFilter[];
  onChangeAnyFilter: (
      filters: { [index: string]: string },
      shouldUpdate: boolean,
      cleanFilters?: boolean
  ) => void;
  shouldUpdate: boolean;
  initFilters: any;
  filters: { [index: string]: string };
}

// let filters: { [index: string]: string } = {};

const FilterWrapperForList = (props: FilterWrapperProps) => {
  const [initFiltersHasBeenCharged, setInitFiltersHasBeenCharged] = useState<boolean>(false);
  const [showAll, setShowAll] = useState<boolean>(false);

  // useEffect(() => {
  //   if (!initFiltersHasBeenCharged && props.filtersConfigList.length > 0) {
  //     let _filters = { ...props.filters };
  //     _filters = props.initFilters;
  //     setInitFiltersHasBeenCharged(true);
  //     props.filtersConfigList.forEach((filterConfig) => {
  //       if (filterConfig.defaultValue) {
  //         _filters[filterConfig.name] = filterConfig.defaultValue;
  //       }
  //     });
  //     props.onChangeAnyFilter(_filters, !props.shouldUpdate);
  //   }
  // }, [initFiltersHasBeenCharged, props]);
  useEffect(() => {
    // filters = props.filters;
  }, [props.filters]);

  useEffect(() => {}, [props.filtersConfigList]);

  const onChangeToggle = (e: any, checked?: boolean) => {
    let val = '1';

    props.filtersConfigList.forEach((fc) => {
      if (fc.name === e.target.id && fc.checkBoxValue) {
        val = fc.checkBoxValue;
      }
    });
    let _filters = { ...props.filters };
    _filters[e.target.id] = _filters[e.target.id] === val ? '' : val;
    props.onChangeAnyFilter(_filters, !props.shouldUpdate);
  };

  const onChangeToggleButton = (e: any, name: string) => {
    let val = '1';
    props.filtersConfigList.forEach((fc: any) => {
      if (fc.name === name && fc.checkBoxValue) {
        val = fc.checkBoxValue;
      }
    });
    let _filters = { ...props.filters };

    _filters[name] = _filters[name] === val ? '' : val;
    props.onChangeAnyFilter(_filters, !props.shouldUpdate);
  };

  const onChangeFilter = (e: any) => {
    let _filters = { ...props.filters };

    if (e.target.type === 'checkbox') _filters[e.target.name] = _filters[e.target.name] === '1' ? '' : '1';
    else _filters[e.target.name] = e.target.value;
    props.onChangeAnyFilter(_filters, !props.shouldUpdate);
  };

  const _onChangeSelectFilter = (option: any, name: string) => {
    let _filters = { ...props.filters };

    _filters[name] = option.key;
    props.onChangeAnyFilter(_filters, !props.shouldUpdate);
  };

  const _onChangeSelectFilterForOrganization = (key: string, name: string) => {
    let _filters = { ...props.filters };

    _filters[name] = key;
    props.onChangeAnyFilter(_filters, !props.shouldUpdate);
  };

  const onClearFilters = (e: any) => {
    let _filters = { ...props.filters };

    for (let prop in _filters) {
      let config = props.filtersConfigList.find((filterConfig) => filterConfig.name === prop);
      let defaultVal: any = config?.defaultValue;
      if (config?.defaultValue instanceof Date) {
        const d = DateTime.fromJSDate(config?.defaultValue);
        defaultVal = d.toUTC().toISO();
      }

      if (config?.defaultValue) _filters[prop] = defaultVal;
      else _filters[prop] = '';
    }
    props.onChangeAnyFilter(_filters, !props.shouldUpdate, true);
  };

  const handleDateChangeWrapper = (e: any, name: string, isMonthSelector: boolean) => {
    let _filters = { ...props.filters };

    if (isMonthSelector) {
      e.setDate(1);
    }
    const d = DateTime.fromJSDate(e);
    //@ts-ignore
    _filters[name] = d.toUTC().toISO();
    props.onChangeAnyFilter(_filters, !props.shouldUpdate);
    let _d = new Date(_filters[name]);
    return _d;
  };

  const getOptionByValue = (opt: any, context: any) => {
    let ret: string = '';
    if (opt) {
      let itemSelected = context.find((e: any) => e.key === opt);
      ret = itemSelected ? itemSelected.key : '';
    }
    return ret;
  };

  const getOptionByValueForOrganization = (opt: any, context: OrganizationChartNode[] | undefined) => {
    let ret: string = '';
    if (opt) {
      if (context) {
        let itemSelected = context.find((e) => e.getId() === opt);
        ret = itemSelected ? itemSelected.getId() : '';
      }
    }
    return ret;
  };

  // Estilos sueltos
  const stackStyles: Partial<IStackStyles> = { root: { height: 32, fontSize: 12 } };
  const FilterIcon: IIconProps = { iconName: 'Filter' };
  const FilterClearIcon: IIconProps = { iconName: 'ClearFilter' };
  const dropdownStyles: Partial<IDropdownStyles> = {
    // dropdown: { minWidth: 150, maxWidth: 150 },
    callout: { minWidth: 300, maxWidth: 300 },
    title: {
      border: '1px solid #aaa',
    },
  };

  const CalendarStyles: Partial<IDatePickerStyles> = {
    textField: {
      fieldGroup: {
        border: '1px solid #aaa',
      },
    },
  };
  const controlStyles = {
    root: {
      maxWidth: '300px',
    },
    label: {
      color: '#aaa',
    },
    fieldGroup: {
      border: '1px solid #aaa',
    },
  };

  const textFieldStyles: Partial<ITextFieldStyles> = {
    field: {
      '::placeholder': {
        color: '#aaa',
      },
    },
    subComponentStyles: {
      label: {
        root: {
          padding: '1.5px 0px',
        },
      },
    },
  };
  return (
      <div>
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-md6 ms-hiddenXlUp">
              <Text block variant="medium">
                {t('Filtros')}
              </Text>
            </div>
            <div className="ms-Grid-col ms-md6 ms-xl12 ms-xxl12">
              <CommandBarButton
                  onClick={onClearFilters}
                  className="float-right-media bg-transparent"
                  allowDisabledFocus
                  styles={stackStyles}
                  iconProps={FilterClearIcon}
                  text={t('Limpiar')}
              />

              {!showAll && (
                  <CommandBarButton
                      className="float-right bg-transparent"
                      onClick={() => setShowAll(true)}
                      styles={stackStyles}
                      iconProps={FilterIcon}
                      text={t('Más')}
                  />
              )}
              {showAll && (
                  <CommandBarButton
                      onClick={() => setShowAll(false)}
                      className="float-right bg-transparent"
                      styles={stackStyles}
                      iconProps={FilterClearIcon}
                      text={t('Menos')}
                  />
              )}
            </div>
          </div>

          <div className="ms-Grid-row" dir="ltr">
            {/* <div className="ms-Grid-col mt-more-filters"></div> */}
            {props.filtersConfigList
                ? props.filtersConfigList.map((filterConfig, index) => {
                  if (!showAll && !filterConfig.quick) {
                    return null;
                  }
                  return (
                      <div className="ms-Grid-col ms-xl12" key={index}>
                        <React.Fragment key={index}>
                          {!filterConfig.hidden && filterConfig.type === TYPE_INPUT ? (
                              <TextField
                                  label={filterConfig.placeholder}
                                  autoComplete={'off'}
                                  onChange={onChangeFilter}
                                  name={filterConfig.name}
                                  value={props.filters[filterConfig.name] ? props.filters[filterConfig.name] : ''}
                                  styles={textFieldStyles}
                                  className="filter-textfield-list"
                                  disabled={filterConfig.disabled}
                                  placeholder={filterConfig.example}
                              />
                          ) : null}

                          {!filterConfig.hidden &&
                              filterConfig.type === TYPE_DROPDOWN &&
                              filterConfig.contextForDropdown && (
                                  <Dropdown
                                      className="filter-textfield-list"
                                      label={filterConfig.placeholder}
                                      options={filterConfig.contextForDropdown}
                                      selectedKey={getOptionByValue(
                                          props.filters[filterConfig.name],
                                          filterConfig.contextForDropdown
                                      )}
                                      onChange={(
                                          event: React.FormEvent<HTMLDivElement>,
                                          option?: IDropdownOption<any> | undefined,
                                          index?: number | undefined
                                      ) => {
                                        _onChangeSelectFilter(option, filterConfig.name);
                                      }}
                                      disabled={filterConfig.disabled}
                                      styles={dropdownStyles}
                                  />
                              )}
                          {!filterConfig.hidden &&
                              filterConfig.type === TYPES.COMBOBOX &&
                              filterConfig.contextForOrganizationChartDropdown && (
                                  <OrganizationChartDropdown
                                      className="filter-textfield-list"
                                      label={filterConfig.placeholder}
                                      options={
                                        filterConfig.contextForOrganizationChartDropdown
                                            ? filterConfig.contextForOrganizationChartDropdown
                                            : []
                                      }
                                      selectedKey={getOptionByValueForOrganization(
                                          props.filters[filterConfig.name],
                                          filterConfig.contextForOrganizationChartDropdown
                                      )}
                                      onChange={(node: OrganizationChartNode) => {
                                        _onChangeSelectFilterForOrganization(node.getId(), filterConfig.name);
                                      }}
                                  />
                              )}

                          {!filterConfig.hidden &&
                              filterConfig.type === TYPES.COMBOBOX &&
                              filterConfig.contextForCombobox && (
                                  <>
                                    <label className="ms-Label label-filter mt-2">{filterConfig.placeholder}</label>
                                    <ComboBoxSearch
                                        options={filterConfig.contextForCombobox ? filterConfig.contextForCombobox : []}
                                        selected={[
                                          getOptionByValue(
                                              props.filters[filterConfig.name],
                                              filterConfig.contextForCombobox
                                          ),
                                        ]}
                                        onSelectOption={(opt) => {
                                          _onChangeSelectFilter(opt, filterConfig.name);
                                        }}
                                        cleanOnSelect={false}
                                        className="combo-box-filter-detaillist"
                                    ></ComboBoxSearch>
                                  </>
                              )}

                          {!filterConfig.hidden && filterConfig.type === TYPE_CHECKBOX ? (
                              <div className="checkbox c-checkbox">
                                <label className="mb-1">
                                  <Toggle
                                      label={filterConfig.labelForCheckbox}
                                      id={String(filterConfig.name)}
                                      checked={Boolean(props.filters[filterConfig.name])}
                                      styles={controlStyles}
                                      onClick={onChangeToggle}
                                      className={'filter-textfield-list'}
                                      disabled={filterConfig.disabled}
                                  />{' '}
                                </label>
                              </div>
                          ) : null}

                          {!filterConfig.hidden && filterConfig.type === TYPE_BUTTON ? (
                              <DefaultButton
                                  toggle
                                  primary={!!props.filters[filterConfig.name]}
                                  checked={!!props.filters[filterConfig.name]}
                                  text={filterConfig.labelForCheckbox}
                                  name={filterConfig.name}
                                  className="btn-filter-wrapper"
                                  onClick={(e) => onChangeToggleButton(e, filterConfig.name)}
                                  disabled={filterConfig.disabled}
                              />
                          ) : null}

                          {!filterConfig.hidden && filterConfig.type === TYPE_DATE ? (
                              <>
                                <DatePicker
                                    strings={getLocalizedStrings()}
                                    label={filterConfig.placeholder}
                                    onSelectDate={(e) =>
                                        handleDateChangeWrapper(e, filterConfig.name, !!filterConfig.isMonthSelector)
                                    }
                                    formatDate={filterConfig.formatDate ? filterConfig.formatDate : DateCustom.formatDateForDatePicker}
                                    value={
                                      props.filters[filterConfig.name]
                                          ? new Date(props.filters[filterConfig.name])
                                          : undefined
                                    }
                                    styles={CalendarStyles}
                                    className="filter-textfield-list"
                                    disabled={filterConfig.disabled}
                                    calendarProps={filterConfig.calendarProps}
                                />
                              </>
                          ) : null}

                          {!filterConfig.hidden && filterConfig.type === TYPE_DATETIME ? (
                              <div className="">
                                <DatePicker
                                    strings={getLocalizedStrings()}
                                    label={filterConfig.placeholder}
                                    onChange={(e: any) =>
                                        handleDateChangeWrapper(e, filterConfig.name, !!filterConfig.isMonthSelector)
                                    }
                                    className="filter-textfield-list"
                                    styles={CalendarStyles}
                                    disabled={filterConfig.disabled}
                                />
                                <i className=" into-input fa fa-calendar"></i>
                              </div>
                          ) : null}

                          {/* {filterConfig.customFilter ? <div>{filterConfig.customFilter}</div> : null} */}
                        </React.Fragment>
                      </div>
                  );
                })
                : null}
          </div>
        </div>
      </div>
  );
};

export default FilterWrapperForList;
