import XhrRequestHandler, {
  getQueryParamsExpression,
  QueryParam,
  XhrRequestError,
} from '../../lib/XhrRequestHandler';
import DocumentsSystem, { IDocumentType, IStoredProjectActivityDocument } from '../documents/DocumentsSystem';
import { NonWorkingDaysSystem } from '../non_working_days/NonWorkingDaysSystem';
import CollaboratorSystem, { ICollaborator } from '../collaborators/CollaboratorSystem';
import ProjectsSystem from '../projects/ProjectsSystem';
import { DateCustom } from '../../lib/DateCustom';
import ActivitySystemOptions, { ReplicateActivityData } from './ActivitySystemOptions';
import { Activity } from './Activity';
import Project from '../projects/Project';
import CollaboratorActivity, {
  ICollaboratorActivity,
} from '../collaborators/collaborator_activity/CollaboratorActivity';
import CollaboratorTeam, { ICollaboratorTeam } from '../collaborators/collaborator_team/CollaboratorTeam';
import CompanySystem from '../company/CompanySystem';
import { BillableDistribution, IBillableDistribution } from '../billable_distribution/BillableDistribution';
import { ProjectCompanyJson } from '../projects/ProjectCompany';
import ProjectCompanySaved from '../projects/ProjectCompanySaved';
import { Collaborator } from '../collaborators/Collaborator';
import SystemDocumentType from '../documents/SystemDocumentType';
import { ActivityDocument } from '../documents/ActivityDocument';
import { SystemDateParser } from '../SystemDateParser';
import { ActivityFromExcelFormToPost } from '../../Components/MassiveActivitiesUploadAssistant/MassiveActivitiesUploadAssistant';
import { IActivitiesForProcess } from '../../Components/MassiveActivitiesChecker/MassiveActivitiesChecker';
import CollaboratorLicenseSystem from '../collaborators/collaborator_license/CollaboratorLicenseSystem';
import { ICalendarHorasEsperadasProgressBar } from '../../Components/Calendar/CalendarHorasEsperadasProgressBar';
import { DateTime } from 'luxon';
import { ActivityPending, ActivityPendingJson } from './ActivityPending';

export interface ActivityJson {
  act_id: string;
  act_horas: number;
  act_proid: string;
  act_nombre: string;
  act_inicio: string;
  act_fin: string;
  act_timezone: string;
  act_descripcion: string;
  act_activo: boolean;
  act_carga_colid: string;
  act_estado: string;
  act_owner_colid: string;
  act_ultima_mod_fecha: string;
  act_refer_outlook: string;
  act_replicada_de_actid: string;
  distribucionFacturableList?: IBillableDistribution[];
  documentoXActividadList?: IStoredProjectActivityDocument[];
  colaboradorActividadList?: ICollaboratorActivity[];
  colaborador?: any;
  proyecto?: any;
}

export interface IEventDataChangeDate {
  id: string;
  start: string | Date;
  end: string | Date;
}

export interface IActivityProjectContext {
  colaboradores: any[];
  documentos: any[];
  empresas: any[];
  pro_inicio: Date | string;
  pro_fin: Date | string;
}

export interface IActivityOutlook {
  email: string;
  start: Date | string;
  end: Date | string;
  subject: string;
  id: string;
  oculta: boolean;
}

export interface IHorasEsperadasPanel {
  horasEsperadas: number;
  horasCargadasPlantilla: number;
}

export interface IActivityCommonContext {
  colaboradores: any[];
  documentos_tipo: any[];
}

export interface IProjectContextForActivity {
  proyecto: IActivityProjectContext;
}

export interface DuplicateActivityData {
  act_inicio: Date | string;
}

export interface IActivitiesProcessed {
  actId: string;
  ok: boolean;
  msgError?: string;
}

export class ActivitySystem {
  static readonly duplicatedActivityErrorCode = 'ERR_DUP_ACT';
  static readonly dateBlockedActivityErrorCode = 'ERR_DATE_BLOCKED';

  private static readonly activityDVMBase = '/actividadDVM';
  static readonly commonContextUrl = `${this.activityDVMBase}/common-context`;

  private readonly activityBase = '/actividades';
  private readonly activityDVMBase = ActivitySystem.activityDVMBase;
  private readonly activityImportBase = '/importacion-actividades';

  constructor(
      private requestHandler: XhrRequestHandler,
      private dateParser: SystemDateParser,
      private collaboratorSystem: CollaboratorSystem,
      private projectSystem: ProjectsSystem,
      private activitySystemOptions: ActivitySystemOptions,
      private companySystem: CompanySystem,
      private nonWorkingDaysSystem: NonWorkingDaysSystem,
      private collaboratorLicenseSystem: CollaboratorLicenseSystem
  ) {}

  getDateParser(): SystemDateParser {
    return this.dateParser;
  }

  async getListForCalendar(start: string, end: string, query: string): Promise<ActivityJson[]> {
    const data = await this.requestHandler.get<ActivityJson[]>(
        `${this.activityBase}/calendario/${start}/${end}${query}`
    );
    return data;
  }

  async getActivity(id: string): Promise<Activity> {
    const activity = await this.requestHandler.get<ActivityJson>(`${this.activityBase}/${id}`);
    return this.mapActivity(activity);
  }

  async getList(params?: QueryParam[]): Promise<any> {
    const query = getQueryParamsExpression(params);
    return await this.requestHandler.get<ActivityJson[]>(`${this.activityBase}${query}`);
  }

  async getActivitiesByProjectId(pro_id: string) {
    return await this.requestHandler.get<ActivityJson[]>(`${this.activityBase}?proId=${pro_id}`);
  }

  async getEntity(id: string) {
    return await this.requestHandler.get<ActivityJson>(`${this.activityBase}/${id}`);
  }

  async save(activity: Activity): Promise<Activity> {
    const saveData = activity.toJson();
    const savedActivityResult = await this.requestHandler.post<ActivityJson, ActivityJson>(
        this.activityBase,
        saveData
    );
    return this.mapActivity(savedActivityResult);
  }

  async checkDuplicates(activity: Activity, except: string = ''): Promise<boolean> {
    const name = activity.getName();
    const date = String(DateTime.fromJSDate(activity.getStart()).toUTC().toISO());
    const hasDuplicates = await this.requestHandler.get<null | ActivityJson>(
        `${this.activityBase}/duplicates?name=${name}&date=${date}${except ? '&except=' + except : ''}`
    );
    return hasDuplicates !== null && hasDuplicates.act_id ? true : false;
  }

  async saveFromExcelForm(
      activities: ActivityFromExcelFormToPost[],
      col_id: string
  ): Promise<ActivityFromExcelFormToPost[]> {
    const savedActivityResult = await this.requestHandler.post<ActivityFromExcelFormToPost[], unknown>(
        `${this.activityImportBase}/import`,
        {
          activities: activities,
          col_id: col_id,
          timezone: this.dateParser.getUserTimezone(),
        }
    );
    return savedActivityResult;
  }

  getDownloadModelExcelURI(col_id: string, period: Date) {
    const _period = DateTime.fromJSDate(period).toFormat('dd-LL-yyyy');
    let url = `${this.activityImportBase}/excel-model/${col_id}?period=${_period}`;
    return `${this.requestHandler.requestUrl(url)}`;
  }

  private documentsSystem(): DocumentsSystem {
    return this.projectSystem.getDocumentsSystem();
  }

  async saveChangeDateFromCalendar(eventToChangeDate: IEventDataChangeDate) {
    return await this.requestHandler.put<any, IEventDataChangeDate>(
        `${this.activityBase}/changeDateFromCalendar/${eventToChangeDate.id}`,
        eventToChangeDate
    );
  }

  async getContextFromProyecto(id: string) {
    let ret = await this.requestHandler.get<IProjectContextForActivity>(
        `${this.activityDVMBase}/proyecto-context/${id}`
    );

    return this.mapContextFromProyecto(ret);
  }

  private mapContextFromProyecto(context: IProjectContextForActivity) {
    let _context = context;

    _context.proyecto.colaboradores = context.proyecto.colaboradores.map((coleq: ICollaboratorTeam) =>
        CollaboratorTeam.buildFrom(this.collaboratorSystem, coleq)
    );
    _context.proyecto.empresas = context.proyecto.empresas.map((exp: ProjectCompanyJson) =>
        ProjectCompanySaved.fromJson(exp)
    );

    return _context;
  }

  async getCommonContext() {
    let ret = await this.requestHandler.get<IActivityCommonContext>(ActivitySystem.commonContextUrl);
    return this.mapCommonContext(ret);
  }

  // Todo, ver mejoras sobre este metodo
  private async mapActivity(activity: ActivityJson /*, deepMode: boolean = true*/) {
    // const project: Project = activity.proyecto
    //   ? Project.fromIProject(this.projectSystem, activity.proyecto)
    //   : await this.projectSystem.getById(activity.act_proid);

    const project = activity.proyecto ? await Project.fromJson(this.projectSystem, activity.proyecto) : null;

    const documentActivityList: ActivityDocument[] = [];
    const documentErrors: string[] = [];

    if (project) {
      if (activity.documentoXActividadList) {
        for (const dxa of activity.documentoXActividadList) {
          // TODO, 6/3/2023 , revisar con Tomas esta instanciación. Si no encuentro documento, entonces lo instancio desde dxa_docid.
          if (/*deepMode && */ dxa.documento) {
            try {
              // let document = await this.projectSystem.getDocumentById(dxa.documento.doc_id);
              let document = this.documentsSystem().getSystemDocumentFromIUploadedDocument(dxa.documento);
              let ret = ActivityDocument.fromIStoredProjectActivityDocument(
                  this.projectSystem,
                  document,
                  project,
                  dxa
              );
              documentActivityList.push(ret);
            } catch (e) {
              let errorMsg = e instanceof XhrRequestError ? e.getApiErrorMessage() : 'Error inesperado';
              documentErrors.push(errorMsg);
            }
          }
        }

        // activity.documentoXActividadList.forEach(async (dxa: IStoredProjectActivityDocument) => {
        // });
      }
    }

    let collaboratorActivity: CollaboratorActivity[] = [];
    if (activity.colaboradorActividadList) {
      collaboratorActivity = activity.colaboradorActividadList.map((colact: ICollaboratorActivity) =>
          CollaboratorActivity.fromICollaboratorActivity(this.collaboratorSystem, colact)
      );
    }

    let billableDistribution: BillableDistribution[] = [];
    if (activity.distribucionFacturableList) {
      billableDistribution = activity.distribucionFacturableList.map((disfa: IBillableDistribution) =>
          BillableDistribution.fromIBillableDistribution(this.companySystem, disfa)
      );
    }

    return Activity.fromJson(
        this.dateParser,
        project,
        collaboratorActivity,
        billableDistribution,
        documentActivityList,
        activity,
        documentErrors
    );
  }

  private async mapActivityPending(activity: ActivityPendingJson) {
    const project = activity.proyecto ? await Project.fromJson(this.projectSystem, activity.proyecto) : null;

    const documentActivityList: ActivityDocument[] = [];
    const documentErrors: string[] = [];

    if (project) {
      if (activity.documentoXActividadList) {
        for (const dxa of activity.documentoXActividadList) {
          // TODO, 6/3/2023 , revisar con Tomas esta instanciación. Si no encuentro documento, entonces lo instancio desde dxa_docid.
          if (/*deepMode && */ dxa.documento) {
            try {
              // let document = await this.projectSystem.getDocumentById(dxa.documento.doc_id);
              let document = this.documentsSystem().getSystemDocumentFromIUploadedDocument(dxa.documento);
              let ret = ActivityDocument.fromIStoredProjectActivityDocument(
                  this.projectSystem,
                  document,
                  project,
                  dxa
              );
              documentActivityList.push(ret);
            } catch (e) {
              let errorMsg = e instanceof XhrRequestError ? e.getApiErrorMessage() : 'Error inesperado';
              documentErrors.push(errorMsg);
            }
          }
        }

        // activity.documentoXActividadList.forEach(async (dxa: IStoredProjectActivityDocument) => {
        // });
      }
    }

    let collaboratorActivity: CollaboratorActivity[] = [];
    if (activity.colaboradorActividadList) {
      collaboratorActivity = activity.colaboradorActividadList.map((colact: ICollaboratorActivity) =>
          CollaboratorActivity.fromICollaboratorActivity(this.collaboratorSystem, colact)
      );
    }

    let billableDistribution: BillableDistribution[] = [];
    if (activity.distribucionFacturableList) {
      billableDistribution = activity.distribucionFacturableList.map((disfa: IBillableDistribution) =>
          BillableDistribution.fromIBillableDistribution(this.companySystem, disfa)
      );
    }

    return ActivityPending.fromJson(
        this.dateParser,
        project,
        collaboratorActivity,
        billableDistribution,
        documentActivityList,
        activity,
        documentErrors
    );
  }

  private mapCommonContext(context: IActivityCommonContext) {
    let _context = context;

    _context.colaboradores = context.colaboradores.map((col: ICollaborator) =>
        Collaborator.fromICollaborator(this.collaboratorSystem, col)
    );
    _context.documentos_tipo = context.documentos_tipo.map((doct: IDocumentType) =>
        SystemDocumentType.fromIDocumentType(doct)
    );

    return _context;
  }

  async getEventsOutlook(desde: Date, hasta: Date, ocultas: boolean = false) {
    const desdeStr = DateCustom.FormatDatetoAMDStr(desde);
    const hastaStr = DateCustom.FormatDatetoAMDStr(hasta);

    const query = getQueryParamsExpression([{ name: 'ocultas', value: ocultas }]);

    return await this.requestHandler.get<IActivityOutlook[]>(
        `${this.activityBase}/calendario-outlook/${desdeStr}/${hastaStr}${query}`
    );
  }

  async getHorasEsperadas(desde: Date, hasta: Date) {
    const desdeStr = DateCustom.FormatDatetoAMDStr(desde);
    const hastaStr = DateCustom.FormatDatetoAMDStr(hasta);

    return await this.requestHandler.get<ICalendarHorasEsperadasProgressBar>(
        `${this.activityBase}/horas-esperadas/${desdeStr}/${hastaStr}`
    );
  }

  getBaseUrlForDatagrid() {
    return this.requestHandler.requestUrl(`${this.activityBase}/datagrid`);
  }

  delete(activity: Activity, allAfterThis = false, all = false) {
    return this.activitySystemOptions.delete(activity.getId(), allAfterThis, all);
  }

  reactivate(activity: Activity) {
    return this.activitySystemOptions.reactivate(activity.getId());
  }

  duplicate(activity: Activity, data: DuplicateActivityData) {
    return this.activitySystemOptions.duplicate(activity.getId(), data);
  }

  replicate(activity: Activity, data: ReplicateActivityData) {
    return this.activitySystemOptions.replicate(activity.getId(), data);
  }

  calculateReplica(activity: Activity, data: ReplicateActivityData) {
    return this.activitySystemOptions.calculateReplica(activity.getId(), data);
  }

  getReplicateContext(activity: Activity) {
    return this.activitySystemOptions.getReplicateContext(activity.getStart(), activity.getId());
  }

  getQtyPendingReplicatedActivities(act_id: string) {
    return this.activitySystemOptions.getQtyPendingReplicatedActivities(act_id);
  }

  getPendingReplicatedActivities(act_id: string) {
    return this.activitySystemOptions.getPendingReplicatedActivities(act_id);
  }

  getProximoDiaHabil(fecha: Date | string) {
    return this.nonWorkingDaysSystem.getProximoDiaHabil(fecha);
  }

  async declineTag(colact_id: string, allAfterThis: boolean, all: boolean) {
    const query = getQueryParamsExpression([
      { name: 'allAfterThis', value: allAfterThis },
      { name: 'all', value: all },
    ]);
    return await this.requestHandler.delete(`${this.activityBase}/cancel-tag/${colact_id}${query}`);
  }

  async acceptTag(colact_id: string, horas: number, allAfterThis: boolean, all: boolean) {
    return await this.requestHandler.put(`${this.activityBase}/accept-tag/${colact_id}`, {
      horas,
      allAfterThis,
      all,
    });
  }

  async getAllPendingActivities(page:number, perPage:number) {
    const data = await this.requestHandler.get<{ activities: ActivityPendingJson[], total: number }>(
        `${this.activityBase}/activities-pending?page=${page}&perPage=${perPage}`
    );
    let ret: { activities: ActivityPending[], total: number } = { activities: [], total: 0 };
    for (const act of data.activities) {
      let actMapped = await this.mapActivityPending(act /*, false*/);
      ret.activities.push(actMapped);
    }
    ret.total= data.total;
    return ret;
  }

  async processPendingActivities(activitiesToProcess: IActivitiesForProcess[]) {
    const ret = await this.requestHandler.put<IActivitiesProcessed[], IActivitiesForProcess[]>(
        `${this.activityBase}/modify-tags`,
        activitiesToProcess
    );

    // let ret: Activity[] = [];
    // for (const act of processActivityResult) {
    //   let actMapped = await this.mapActivity(act);
    //   ret.push(actMapped);
    // }

    return ret;
  }

  async getAllActivitiesCreated(from: Date, to: Date, projectId: string) {
    const _from = DateTime.fromJSDate(from).toFormat('dd-LL-yyyy');
    const _to = DateTime.fromJSDate(to).toFormat('dd-LL-yyyy');

    const data = await this.requestHandler.get<ActivityJson[]>(
        `${this.activityBase}/activities-for-edit-distribution?from=${_from}&to=${_to}&proId=${projectId}`
    );
    // let ret: Activity[] = [];
    // for (const act of data) {
    //   let actMapped = await this.mapActivity(act);
    //   ret.push(actMapped);
    // }

    // return ret;
    return data;
  }

  async saveBillableDistribution(disfa: IBillableDistribution[]) {
    const result = await this.requestHandler.put<unknown, IBillableDistribution[]>(
        `${this.activityBase}/save-distribution`,
        disfa
    );
    return result;
  }

  public getCollaboratorLicenseSystem() {
    return this.collaboratorLicenseSystem;
  }

  public getNonWorkingDaysSystem() {
    return this.nonWorkingDaysSystem;
  }

  public getCompanySystem() {
    return this.companySystem;
  }
}

export default ActivitySystem;
