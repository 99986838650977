import React, { useState, useMemo } from 'react';
import { CARD_MODE, GRID_MODE, OPERATORS, TYPES } from '../../common/list-utils';
import { CollaboratorCardList } from './List/CollaboratorCardList';
import { CollaboratorGridList } from './List/CollaboratorGridList';
import { ListColumnFilter } from '../Common/list-entities/ListColumnFilter';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../common/constants';
// import './CollaboratorList.css';
import TimeTaxSupportSystemClient from '../../system';
import useAsyncDataCourier from '../../common/custom-hooks/useAsyncDataCourier';
import MessageCourier from '../../lib/MessageCourier';
import { useTranslation, Trans } from 'react-i18next';

interface ICollaboratorList {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: (state: boolean) => void;
}

export const CollaboratorList = (props: ICollaboratorList) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [mode, setMode] = useState(GRID_MODE);

  const nodes = useAsyncDataCourier(
      () => props.system.getOrganizationChartSystem().getAllNodes(),
      [props.system],
      props.courier
  );

  const baseUrl = React.useMemo(() => {
    return props.system.getCollaboratorSystem().getBaseUrlForDatagrid();
  }, [props]);

  const onItemClick = (item: any) => {
    navigate(`${routes.collaborator}/${item.col_id}`);
  };

  const quickFilters: ListColumnFilter[] = useMemo(
      () => [
        {
          quick: true,
          name: 'col_nombre',
          type: TYPES.INPUT,
          placeholder: t('Nombre'),
          operator: OPERATORS.CONTAINS,
        },
        {
          quick: true,
          name: 'col_apellido',
          type: TYPES.INPUT,
          placeholder: t('Apellido'),
          operator: OPERATORS.CONTAINS,
        },
        {
          quick: true,
          name: 'col_email',
          type: TYPES.INPUT,
          placeholder: t('Email'),
          operator: OPERATORS.CONTAINS,
        },

        {
          quick: false,
          name: 'col_email',
          type: TYPES.INPUT,
          placeholder: t('Email'),
          operator: OPERATORS.CONTAINS,
        },
        {
          quick: true,
          name: 'carcol_norid',
          type: TYPES.COMBOBOX,
          placeholder: t('Sector'),
          operator: OPERATORS.EQUAL,
          contextForOrganizationChartDropdown: nodes || [],
        },
        {
          quick: true,
          name: 'cargo_nombre',
          type: TYPES.INPUT,
          placeholder: t('Cargo'),
          operator: OPERATORS.CONTAINS,
        },
      ],
      [nodes]
  );
  return (
      <>
        {nodes && (
            <>
              {mode === GRID_MODE ? (
                  <CollaboratorGridList
                      changeBlockUI={props.changeBlockUI}
                      baseUrl={baseUrl}
                      quickFilters={quickFilters}
                      onItemClick={onItemClick}
                      onClickCardMode={() => setMode(CARD_MODE)}
                      onClickGridMode={() => setMode(GRID_MODE)}
                      mode={mode}
                      courier={props.courier}
                      keyList={'colaboradores'}
                      system={props.system}
                  ></CollaboratorGridList>
              ) : (
                  <CollaboratorCardList
                      changeBlockUI={props.changeBlockUI}
                      baseUrl={baseUrl}
                      quickFilters={quickFilters}
                      onItemClick={onItemClick}
                      onClickCardMode={() => setMode(CARD_MODE)}
                      onClickGridMode={() => setMode(GRID_MODE)}
                      courier={props.courier}
                      mode={mode}
                  ></CollaboratorCardList>
              )}
            </>
        )}
      </>
  );
};
