import React, { useState } from 'react';
import { IIconProps, IContextualMenuProps, Stack, Link, Icon, mergeStyles } from '@fluentui/react';
import { IconButton } from '@fluentui/react/lib/Button';
import { Text, FontIcon } from '@fluentui/react';
import {
  GRID_MODE,
  TYPE_BUTTON,
  TYPE_CHECKBOX,
  TYPE_COMBOBOX,
  TYPE_DROPDOWN,
  TYPE_DATE,
  TYPE_DATETIME,
} from '../../../common/list-utils';
import i18next, { t } from 'i18next';
import { DateTime } from 'luxon';

interface propsDetail {
  onClickCardMode: any;
  onClickGridMode: any;
  mode: string;
  title: string;
  filtersApplied: any[];
  onClickChipFilter: (filterApplied: any) => void;
}

export const DetailListHeader = (props: propsDetail) => {
  const switchIcon: IIconProps = { iconName: props.mode == GRID_MODE ? 'GridViewSmall' : 'ContactCard' };

  const menuProps: IContextualMenuProps = {
    items: [
      {
        key: 'gridStyle',
        text: t('Modo Lista'),
        iconProps: { iconName: 'GridViewSmall' },
        onClick: props.onClickGridMode,
      },
      {
        key: 'cardStyle',
        text: t('Modo Tarjeta'),
        iconProps: { iconName: 'ContactCard' },
        onClick: props.onClickCardMode,
      },
    ],
    directionalHintFixed: true,
  };

  const iconClass = mergeStyles({
    position: 'relative',
    top: 2,
    left: 2,
  });

  const getLabelAppliedFilter = (filter: any) => {
    if (filter.filterConfig.placeholder) {
      return filter.filterConfig.placeholder + ': ';
    } else {
      return filter.filterConfig.labelForCheckbox;
    }
  };
  const getValueAppliedFilter = (filter: any) => {
    if (
        filter.filterConfig.type === TYPE_COMBOBOX &&
        filter.filterConfig.contextForOrganizationChartDropdown
    ) {
      let optionSelected = filter.filterConfig.contextForOrganizationChartDropdown.find(
          (o: any) => o.getId() == filter.value
      );
      if (optionSelected) return optionSelected.getName();
    }

    if (filter.filterConfig.type === TYPE_DROPDOWN) {
      if (filter.filterConfig.contextForDropdown) {
        let optionSelected = filter.filterConfig.contextForDropdown.find((o: any) => o.key == filter.value);
        if (optionSelected) return optionSelected.text;
      }
    }

    if (filter.filterConfig.type === TYPE_DATE) {
      DateTime.fromJSDate(new Date(filter.value)).toFormat('dd/LL/yyyy');
    }

    if (filter.filterConfig.type === TYPE_DATETIME) {
      if (filter.filterConfig.formatDate) {
        return filter.filterConfig.formatDate(new Date(filter.value));
      } else {
        DateTime.fromJSDate(new Date(filter.value)).toFormat('dd/LL/yyyy HH:mm');
      }
    }

    if (filter.filterConfig.type === TYPE_CHECKBOX || filter.filterConfig.type === TYPE_BUTTON) {
      return '';
    } else {
      return filter.value;
    }
  };
  return (
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm10 ">
            <Text block variant="large" className="text-bold title-filter-wrapper">
              {props.title}
              {props.filtersApplied &&
                  props.filtersApplied.map((filter, i) => {
                    if (filter && filter.filterConfig && !filter.filterConfig.hidden && filter.key) {
                      return (
                          <div key={i} className="chip-filter" onClick={() => props.onClickChipFilter(filter)}>
                            <label className="chip-filter-label">
                              {getLabelAppliedFilter(filter)}
                              {getValueAppliedFilter(filter)}
                              <Icon aria-label="Train" iconName="Cancel" className={iconClass} />
                            </label>
                          </div>
                      );
                    }
                  })}
            </Text>
          </div>

          <div className="ms-Grid-col ms-sm2">
            {/* <IconButton
            disabled={true}
            className="float-right"
            menuProps={menuProps}
            iconProps={switchIcon}
            title={t('Mostrar como')}
          /> */}
          </div>
        </div>
      </div>
  );
};
