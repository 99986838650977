import LazyInitialization from '../../lib/LazyInitialization';
import XhrRequestHandler from '../../lib/XhrRequestHandler';
import { Collaborator } from '../collaborators/Collaborator';
import { User } from '../User';
import { OrganizationChartIntermediateNode } from './OrganizationChartIntermediateNode';
import { OrganizationChartLevel } from './OrganizationChartLevel';
import { OrganizationChartNode } from './OrganizationChartNode';
import { OrganizationChartTopNode } from './OrganizationChartTopNode';

interface IOrganizationChartLevel {
  nio_id: string;
  nio_nombre: string;
}

interface IOrganizationChartNode {
  nor_id: string;
  nor_nombre: string;
  nor_nivel: string;
  nor_padre: string | null;
}

export interface IOrganizationChartCompleteNode extends IOrganizationChartNode {
  nodoOrganigrama: IOrganizationChartCompleteNode | null;
}

class OrganizationChartSystem {
  private readonly entityBase = '/organigrama';

  private levels: LazyInitialization<OrganizationChartLevel[]>;
  private nodes: LazyInitialization<OrganizationChartNode[]>;

  constructor(private requestHandler: XhrRequestHandler) {
    this.levels = new LazyInitialization(() => this.getLevelsRequest());
    this.nodes = new LazyInitialization(() => this.getAllNodesRequest());
  }

  getLevels(): Promise<OrganizationChartLevel[]> {
    return this.levels.value();
  }

  async getLastLevel(): Promise<OrganizationChartLevel> {
    const l = await this.getLevels();
    return [...l].pop()!;
  }

  async getAllNodes(): Promise<OrganizationChartNode[]> {
    return this.nodes.value();
  }

  async getLastLevelNodes(): Promise<OrganizationChartNode[]> {
    const [nodes, levels] = await Promise.all([
      this.requestHandler.get<IOrganizationChartCompleteNode[]>(`${this.entityBase}/last-level-nodes`),
      this.getLevels(),
    ]);

    return nodes.map((node) => this.mapNode(node, levels));
  }

  async getNode(nodeId: string): Promise<OrganizationChartNode> {
    return this.nodes.withCachedValueDo(
        (data) => {
          const node = data.find((node) => node.isIdentifiedBy(nodeId));
          if (!node) {
            throw new Error('Node not found');
          }
          return Promise.resolve(node);
        },
        () => this.getNodeByIdRequest(nodeId)
    );
  }

  private async getNodeByIdRequest(nodeId: string): Promise<OrganizationChartNode> {
    const [node, levels] = await Promise.all([
      this.requestHandler.get<IOrganizationChartCompleteNode>(`${this.entityBase}/node/${nodeId}`),
      this.getLevels(),
    ]);

    return this.mapNode(node, levels);
  }

  mapNode(node: IOrganizationChartCompleteNode, levels: OrganizationChartLevel[]): OrganizationChartNode {
    const level = this.levelById(levels, Number(node.nor_nivel));
    if (node.nodoOrganigrama) {
      const parent = this.mapNode(node.nodoOrganigrama, levels);
      return OrganizationChartIntermediateNode.named(node.nor_nombre, node.nor_id, level, parent);
    }
    return OrganizationChartTopNode.named(node.nor_nombre, node.nor_id, level);
  }

  private levelById(levels: OrganizationChartLevel[], l: number): OrganizationChartLevel {
    return levels.find((level) => level.isIdentidiedBy(l))!;
  }

  private async getLevelsRequest(): Promise<OrganizationChartLevel[]> {
    const data = await this.requestHandler.get<IOrganizationChartLevel[]>(`${this.entityBase}/levels`);

    return data
        .sort((a, b) => Number(a.nio_id) - Number(b.nio_id))
        .map((level) => OrganizationChartLevel.named(level.nio_nombre, Number(level.nio_id)));
  }

  private async getAllNodesRequest(): Promise<OrganizationChartNode[]> {
    const [nodes, levels] = await Promise.all([
      this.requestHandler.get<IOrganizationChartNode[]>(`${this.entityBase}/nodes`),
      this.getLevels(),
    ]);

    const result: OrganizationChartNode[] = [];
    nodes
        .sort((a, b) => Number(a.nor_nivel) - Number(b.nor_nivel))
        .forEach((node) => {
          const level = this.levelById(levels, Number(node.nor_nivel));
          if (node.nor_padre) {
            const parent = result.find((possibleParent) =>
                possibleParent.isIdentifiedBy(node.nor_padre || '')
            )!;
            result.push(OrganizationChartIntermediateNode.named(node.nor_nombre, node.nor_id, level, parent));
          } else {
            result.push(OrganizationChartTopNode.named(node.nor_nombre, node.nor_id, level));
          }
        });

    return result;
  }

  public async getNodeOf(col: Collaborator) {
    let pos = await col.getPosition();
    let node = await pos.getOrganizationNode();
    return node;
  }

  async getAvailableNodesOf(user: User) {
    const filtersDataNodes = await this.getAllNodes();
    const nodeOfLoggedCol = await this.getNodeOf(user.getCollaborator());
    let _nodesFiltered = filtersDataNodes;
    if (!user.hasPowerUserPrivileges()) {
      _nodesFiltered = filtersDataNodes.filter((node) => node.isDescendantOf(nodeOfLoggedCol));
    }
    return _nodesFiltered;
  }
}

export default OrganizationChartSystem;
