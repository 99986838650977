import React, { useEffect, useState } from 'react'
import { IList_cnih, IUpdateInformacionGeneral } from './InformacionGeneral';
import { Icon, IColumn, PrimaryButton ,Checkbox, TextField, TooltipHost, DatePicker } from '@fluentui/react';
import CustomModal, { ModalConfirm } from './CustomModal';
import CustomTable from './CustomTable';
import { spanishDatePickerStrings } from '../collaboratorCreateView';
import { DateCustom } from '../../../../lib/DateCustom';
import {convertStringToDateProyectoEnalce } from '../../../../common/custom-hooks/TodayDate';
import { useForm } from 'react-hook-form';
import formatearFecha from '../../../../common/formatDate';
import "./ProyectoEnlace.css";

function ProyectoEnlace({datosColaborador,system}:IUpdateInformacionGeneral) {

  const [modalProyecto,setModalProyecto] = useState<boolean>(false);
  const [modificarId,setModificarId] = useState<string | undefined>(undefined);
  const [modificarPorcentaje,setModificarPorcentaje] = useState<string | undefined>(undefined);
  const [modificarFechaAlta,setModificarFechaAlta] = useState<string | undefined>(undefined);
  const [modificarFechaBaja,setModificarFechaBaja] = useState<string | undefined>(undefined);
  const [modificarActivo,setModificarActivo] = useState<string | undefined>(undefined);
  const [modificarCrear,setModificarCrear] = useState<boolean>(true);
  
  const [alert,setAlert] = useState<boolean>(false);
  const [modalactive,setModalActive] = useState<boolean>(false);
  const [datosDesactivar,setDatosDesactivar] = useState<IList_cnih | undefined>(undefined);
  const [item,setItem] = useState<IList_cnih[] | undefined>(undefined);
  const [recetValue ,setRecetValue] = useState<boolean>(true);
  const [minDateVigencia,setMinDateVigencia] = useState<Date | undefined>(undefined);
  
  const {register,setValue,handleSubmit,formState: { errors },watch,reset,clearErrors} = useForm();
  
  const columns: IColumn[] = [
    { key: 'column1', name: 'Desde', fieldName: 'cnih_fecha_alta', minWidth: 80, isResizable: true },
    { key: 'column2', name: 'Hasta', fieldName: 'cnih_fecha_baja', minWidth: 80, isResizable: true },
    { key: 'column3', name: 'Porcentaje', fieldName: 'cnih_porcentaje_excento', minWidth: 70, isResizable: true,
    onRender: (item) => (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                  { item.cnih_porcentaje_excento.split(".")[0] + "%"}
              </div> )
       },
      {
        key: 'column4', name: 'Activo', fieldName: 'cnih_activo', minWidth: 50, isResizable: true,
        onRender: (item) => (<div style={{display:"flex",alignItems:"center",justifyContent:"center" }}>
          <Checkbox key={"001"} checked={item.cnih_activo === "1"?true:item.cnih_activo==="0"?false:undefined} onChange={(ev, checked) => hamdlerChecked(item)} />
            </div> )
      },
      {
        key: 'column5', name: 'Eliminar', fieldName: 'Eliminar', minWidth: 60, isResizable: true,
        onRender: (item) => ( <div style={{display:"flex",alignItems:"center",justifyContent:"center" ,color:"red",cursor:"pointer"}}>
          <Icon style={{fontSize:"20px"}} iconName='Delete' onClick={()=>handlerClickDelete(item)}/></div>
        )
      },
      {
        key: 'column6', name: 'Modificar', fieldName: 'Modificar', minWidth: 65, isResizable: true,
        onRender: (item) => ( <div style={{display:"flex",alignItems:"center",justifyContent:"center" ,color:"#0078D4",cursor:"pointer"}}>
          <Icon style={{fontSize:"20px"}} iconName='SingleColumnEdit' onClick={() => {
            clearErrors()
            setRecetValue(false);
            setModificarId(item.cnih_id);
            setModificarPorcentaje(item.cnih_porcentaje_excento);
            setModificarFechaAlta(item.cnih_fecha_alta);
            setModificarFechaBaja(item.cnih_fecha_baja);
            setModificarActivo(item.cnih_activo);
            setModificarCrear(false)
            setTimeout(()=>{
              setRecetValue(true);
            },888);
          }}/></div>
        )
      }
    ];
    
    const  hamdlerChecked = (datos:IList_cnih) => {
      setDatosDesactivar(datos); 
      setModalActive(!modalactive);
    }
    
    const hamdlerModificar = async (valor:string) => {
      if(valor === "onConfirm"){
        if(!datosDesactivar)return;
        item?.map((data)=>{
          if(data.cnih_id === datosDesactivar?.cnih_id){
            if(datosDesactivar.cnih_activo === "0"){
              data.cnih_activo = "1";
            }else if(datosDesactivar.cnih_activo === "1"){
              data.cnih_activo = "0";
            }
        }
      });
        setModalActive(!modalactive);
        await system?.modificarOneProyectoEnlace(datosDesactivar);
        setDatosDesactivar(undefined);
        getLIstProyecto();
      }else if(valor === "onclose"){
        setDatosDesactivar(undefined);
        setModalActive(!modalactive);
      }
    }
    
    const handlerClickDelete = (datos:IList_cnih)=>{
      setDatosDesactivar(datos); 
      setAlert(!alert);
    }
    
    const handlerDelete = async (valor:string)=>{
      if(valor === "onConfirm"){
        setModificarCrear(true)
        const data = item?.filter((item)=>item.cnih_id !== datosDesactivar?.cnih_id);
        setItem(data);
        if(!datosDesactivar)return;
        await system?.DelteProyectoEnlace(datosDesactivar?.cnih_id);
        setDatosDesactivar(undefined)
        getLIstProyecto()
      }else if("onclose"){
        setDatosDesactivar(undefined)
      }
      setAlert(!alert);
    }
    
    const HamdlerSubmitCnih = handleSubmit(async (data) => {
      const valor = data as IList_cnih;
      if(!datosColaborador)return;
      if (modificarCrear === true) {
        setRecetValue(false);
        if (item) {
          const newItems = [valor, ...item];
            setItem(newItems);
          }
          await system?.CrearOneProyectoEnlace(valor,datosColaborador?.col_email);
              setTimeout(()=>{
                cleanDataValue();
                getLIstProyecto();
                setRecetValue(true);
              },1000);
      } else if (modificarCrear === false) {
          if (data.cnih_id) {
            setRecetValue(false);
            const datosModif = item?.map((datos) => {
              if (datos.cnih_id === data.cnih_id) {
                return valor;  
              }
              return datos;
            });
            setItem(datosModif);
            setModificarCrear(true);
            await system?.modificarOneProyectoEnlace(valor);
            setTimeout(()=>{
              getLIstProyecto();
              setRecetValue(true);
            },1000);
          }
        }
      });
      
      const getLIstProyecto = async ()=>{
        if(!datosColaborador)return;
        const data = await system?.ProyectoenlaceList(datosColaborador?.col_email);
        setItem(data.List_cnih);
      }
      
      const cleanDataValue = ()=>{
        setModificarId(undefined);
        setModificarFechaAlta(undefined);
        setModificarFechaBaja(undefined);
        setModificarPorcentaje(undefined);
        reset({
            cnih_id: undefined,
            cnih_porcentaje_excento: undefined,
            cnih_fecha_alta: undefined,
            cnih_fecha_baja: undefined,
            cnih_activo: "1",
        });
      }
      
      const handlerModificarCrearSet = ()=>{
        if(modificarCrear === false){
          if(modificarId){
            setValue("cnih_id",modificarId);
            setValue("cnih_porcentaje_excento",modificarPorcentaje? modificarPorcentaje.split(".")[0]:undefined);
            setValue("cnih_fecha_alta",modificarFechaAlta);
            setValue("cnih_fecha_baja",modificarFechaBaja);
            setValue("cnih_activo",modificarActivo);
            setMinDateVigencia(undefined);
        } 
      }else if (modificarCrear === true){
        cleanDataValue();
        if(item){
    
          let convertStringToDat = item[0]?.cnih_fecha_baja ;
          if(convertStringToDat) {
            setMinDateVigencia(convertStringToDateProyectoEnalce(convertStringToDat));
          }else{setMinDateVigencia(undefined);}
        }
      }
    }

    const handlerPorcetajeExceto = (newvalue:string | undefined)=>{
      if(modificarId){
        setModificarPorcentaje(newvalue);
     }
     setValue("cnih_porcentaje_excento",newvalue);
    }
    
    const handlerVigencia = (date:Date | null | undefined) =>{
      if(modificarId) setModificarFechaAlta(date?formatearFecha(date):undefined);
      setValue("cnih_fecha_alta",date?formatearFecha(date):undefined);
    }

    const handlerFechaFin = (date:Date | null | undefined)=>{
      if(modificarId) setModificarFechaBaja(date?formatearFecha(date):undefined);
      setValue("cnih_fecha_baja",date?formatearFecha(date):undefined);
    }

    const handlerFecahaVigencia = ()=>{
      if (item && item[0] && item[0].cnih_fecha_baja) {
        setMinDateVigencia(convertStringToDateProyectoEnalce(item[0].cnih_fecha_baja));
        return true;
    } else {
        setMinDateVigencia(undefined);
        return false;
    }
    }

    useEffect(()=>{
      handlerFecahaVigencia();
      handlerModificarCrearSet();
      register("cnih_fecha_alta",{required:true,validate:(e)=>{
        if(item?.length === 0)return true;
        if(modificarCrear === true){
          if(minDateVigencia === undefined){
          return "error";
          }
          return true
        }else{
          return true;
        }
      }});
    },[modificarCrear,item]);
    
    useEffect(()=>{
      getLIstProyecto(); 
      register("cnih_porcentaje_excento",{required:true,pattern:/^(0|[1-9][0-9]?|100)$/,maxLength:3});
      register("cnih_fecha_baja",{required:false});
    },[]);
return (
  <div className="form_informacion_general">
       <p className="titulo_form_create">
        <Icon iconName="script" style={{fontSize:"25px"}}  />
        Proyecto fijo
       </p>
       <PrimaryButton style={{marginLeft:"15px"}} onClick={()=>setModalProyecto(true)}>
        Ver todos los periodos
       </PrimaryButton>
      <CustomModal
          hidden={modalProyecto}
          onDismiss={()=>setModalProyecto(!modalProyecto)}
          title='Detalles periodo proyecto fijo'
          minWidth={600}
          minHeight={520}
          >
              <div style={{  height: '200px', display: 'block', overflowY: 'auto',}}>
                  <CustomTable
                  items={item}
                  columns={columns}
                  />
              </div>
              <hr /> 
          <div style={{display:"flex",alignItems:"center",justifyContent:"center" }} >
              {recetValue? <form onSubmit={HamdlerSubmitCnih} >
                      <span className={"input_reusable"} >
                              <TextField
                                  key="000345" 
                                  label="% Participacion" placeholder="Escriba el porcentage" type="text"
                                  styles={{root:{width:"170px"}}} 
                                  value={ modificarCrear? undefined:modificarPorcentaje? modificarPorcentaje.split(".")[0]:undefined}
                                  onChange={(event,newvalue)=>{handlerPorcetajeExceto(newvalue)}}/>
                                  { errors.cnih_porcentaje_excento?.type === "required" && <span className="error_TextFile_proyectofijo" >El % de participación no puede ser vacío.</span>}
                                  { errors.cnih_porcentaje_excento?.type === "pattern" && <span className="error_TextFile_proyectofijo" >Este campo solo permite números menores o igual a 100.</span>}
                                  { errors.cnih_porcentaje_excento?.type === "maxLength" && <span className="error_TextFile_proyectofijo" >Este campo solo permite tres dígitos.</span>}   
                      </span>
                    <div>
                      <div style={{display:"flex",flexWrap:"wrap",gap:"5px"}}>
                        <span className="label_reusable">
                          <TooltipHost content={"Seleccione la fecha de ingreso"}>
                              <DatePicker  allowTextInput
                                  strings={spanishDatePickerStrings}
                                  formatDate={DateCustom.formatDateForDatePicker}
                                  placeholder="Seleccione la fecha de ingreso"
                                  label="Vigencia"
                                  styles={{root:{width:"170px"}}} 
                                  minDate={minDateVigencia}
                                  value={modificarFechaAlta? convertStringToDateProyectoEnalce(modificarFechaAlta):undefined}
                                  onSelectDate={(date)=>{handlerVigencia(date) }} />
                          </TooltipHost>
                        </span> 
                          <p style={{marginTop:"30px"}}>hasta</p>
                        <span style={{marginTop:"27px" }} >
                          <TooltipHost content={"Seleccione la fecha de fin"}>
                              <DatePicker  allowTextInput
                                  strings={spanishDatePickerStrings}
                                  formatDate={DateCustom.formatDateForDatePicker}
                                  placeholder="Seleccione la fecha de fin"
                                  styles={{root:{width:"170px"}}} 
                                  minDate={watch("cnih_fecha_alta")?convertStringToDateProyectoEnalce(watch("cnih_fecha_alta")):undefined}
                                  value={ modificarFechaBaja? convertStringToDateProyectoEnalce(modificarFechaBaja):undefined}
                                  onSelectDate={(date)=>{handlerFechaFin(date)}}/>
                          </TooltipHost>
                        </span>
                      </div>
                      { errors.cnih_fecha_alta?.type === "validate" && <span className="error_TextFile_proyectofijo" >Primero debes finalizar el proyecto fijo anterior.</span>}
                      { errors.cnih_fecha_alta?.type === "required" && <span className="error_TextFile_proyectofijo" >La de vigencia no puede ser vacío.</span>}
                    </div>
                     {modificarCrear?
                    <PrimaryButton type="submit" style={{marginLeft:"140px", marginTop:"10px"}}>Crear</PrimaryButton>:
                     <div style={{display:'flex',gap:"20px"}}>
                        <PrimaryButton type="submit" style={{marginLeft:"80px", marginTop:"10px"}} >Modificar</PrimaryButton>
                        <PrimaryButton style={{ marginTop:"10px"}} onClick={()=>{
                          cleanDataValue();
                          setModificarCrear(true)}}>cancelar</PrimaryButton> 
                     </div> }
              </form>:<div className="loader_Proyect"></div>}
          </div>
          <ModalConfirm key={"eliminar"} message='¿Estás seguro de eliminar el periodo?' isOpen={alert} onClose={()=>handlerDelete("onclose")} onConfirm={()=>handlerDelete("onConfirm")} />
          <ModalConfirm key={"desactivar"}
           message={datosDesactivar?.cnih_activo === "1"?'¿Estás seguro de desactivar este periodo?':'¿Estás seguro de activar este periodo?'}
           isOpen={modalactive} onClose={()=>hamdlerModificar("onclose")} onConfirm={()=>hamdlerModificar("onConfirm")} />
      </CustomModal>
  </div>
)
}

export default ProyectoEnlace;