import { IComboBox, IComboBoxOption, ComboBox, IComboBoxProps, IComboBoxStyles } from '@fluentui/react';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface IComboBoxSearch {
  options: IComboBoxOption[];
  selected: string[];
  onSelectOption: (opt: IComboBoxOption) => void;
  comboProps?: IComboBoxProps;
  comboStyles?: IComboBoxStyles;
  className?: any;
  disabled?: boolean;
  cleanOnSelect?: boolean;
}

const useFocus = () => {
  const htmlElRef = useRef<IComboBox>(null);
  const setFocus = () => {
    htmlElRef?.current?.focus();
  };

  return [htmlElRef, setFocus];
};

export const ComboBoxSearch = (props: IComboBoxSearch) => {
  const { t, i18n } = useTranslation();
  const [inputRef, setInputFocus] = useFocus();

  const [optionsFiltered, setOptionsFiltered] = React.useState(props.options);
  const [textDropDown, setTextDropdown] = React.useState<string | undefined>(undefined);
  const [isActive, setIsActive] = React.useState<boolean>(true);

  const onInputValueChange = (value: string) => {
    let _optionsFiltered = [...props.options];
    let save = _optionsFiltered.filter((opt) =>
        opt.text.toLocaleLowerCase().includes(value.toLocaleLowerCase())
    );

    setOptionsFiltered(save);
  };

  React.useEffect(() => {
    if (props.selected[0] === '') {
      setTextDropdown('');
    }
  }, [props.selected]);

  React.useEffect(() => {
    //  EXPERIMENTAL, SI EMPIEZA A FUNCIONAR MAL ESTE COMPONENTE ELIMINAR ELSE -- 09/06/2023
    const text = props.options.find((o) => o.key === props.selected[0]);
    if (text) setTextDropdown(String(text?.text));
    else setTextDropdown('');
  }, []);

  React.useEffect(() => {
    setOptionsFiltered(props.options);
  }, [props.options]);

  const onChangeCustomInput = (
      event: React.FormEvent<IComboBox>,
      option?: IComboBoxOption,
      index?: number,
      value?: string
  ) => {
    if (option) {
      if (props.cleanOnSelect) {
        setTextDropdown('');
      } else {
        setTextDropdown(option.text);
      }
      props.onSelectOption(option);

      destroyAndConstruct();
    }
    setOptionsFiltered(props.options);
  };

  const destroyAndConstruct = () => {
    setIsActive(false);
    setTimeout(() => {
      setIsActive(true);
    }, 50);
    setTimeout(() => {
      // @ts-ignore
      setInputFocus();
    }, 50);
  };

  return (
      <>
        {isActive && (
            <ComboBox
                componentRef={inputRef}
                {...props.comboProps}
                selectedKey={props.selected}
                text={textDropDown}
                autoComplete="off"
                allowFreeform
                shouldRestoreFocus={true}
                openOnKeyboardFocus={true}
                options={optionsFiltered}
                onInputValueChange={onInputValueChange}
                onChange={onChangeCustomInput}
                className={props.className}
                disabled={props.disabled}
                // useComboBoxAsMenuWidth={true}
            />
        )}
      </>
  );
};

export default ComboBoxSearch;
