import React from 'react';
import { Text } from '@fluentui/react/lib/Text';
import { Icon, IconButton, IContextualMenuProps, IIconProps } from '@fluentui/react';
import TimeTaxSupportSystemClient from '../../../system';
import MessageCourier from '../../../lib/MessageCourier';
import { ProjectStatus } from '../../Common/ProjectStatus';
import { Collaborator } from '../../../system/collaborators/Collaborator';
import ProjectConfigurationForm from './ProjectConfigurationForm';
import InputField from '../../../lib/FormValidator/Form/InputField';
import CustomInputIfc from '../../../lib/FormValidator/Form/CustomInputIfc';
import { InputFieldForStartEnd } from '../../../lib/FormValidator/Form/InputFieldForStartEnd';
import ToggleInput from '../../../lib/FormValidator/Form/ToggleInput';
import TextArea from '../../../lib/FormValidator/Form/TextAreaField';
import { ConfirmPreloadTeam } from './ConfirmPreloadTeam';
import CollaboratorTeam from '../../../system/collaborators/collaborator_team/CollaboratorTeam';
import { OrganizationChartNode } from '../../../system/organization_chart/OrganizationChartNode';
import { OrganizationChartDropdown } from '../../Common/OrganizationChartDropdown';
import { t } from 'i18next';
import { useCustomInput } from '../../../common/custom-hooks/useCustomInput';

interface IProjectConfigurationBasic {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  organizationChartNodes: OrganizationChartNode[];
  collaboratorsList: Collaborator[];
  formProject: ProjectConfigurationForm;
  onBlurCustomInput: (customInput: CustomInputIfc) => void;
  onChangeBillable: (checked: boolean) => void;
  onChangeProjectTeam: (team: CollaboratorTeam[]) => void;
}

export const ProjectConfigurationBasicData = (props: IProjectConfigurationBasic) => {
  const [availableNodes, setAvailableNodes] = React.useState<OrganizationChartNode[]>([]);
  const [showDialogPreloadTeam, setShowDialogPreloadTeam] = React.useState<boolean>(false);
  const [collaboratorsInSelectedNode, setCollaboratorsInSelectedNode] = React.useState<Collaborator[]>([]);

  const [projectStatus, setProjectStatus] = React.useState<string>('');

  const nodeInput = useCustomInput(() => {
    return props.formProject.getOrganizationNodeInput();
  }, [props.formProject]);

  React.useEffect(() => {
    setProjectStatus(props.formProject.getStateInput().getValue());
  }, [props.formProject.getStateInput().getValue()]);

  // const lastLevelNode = useAsyncDataCourier(
  //   () => props.system.getOrganizationChartSystem().getLastLevel(),
  //   [props.system],
  //   props.courier
  // );

  React.useEffect(() => {
    setAvailableNodes([...props.organizationChartNodes]);
  }, [props.organizationChartNodes]);

  const onSelectNode = async (input: CustomInputIfc) => {
    if (
        props.formProject
            .getProjectTeamForm()
            .getInputProjectTeamItems()
            .getValue()
            .filter((item) => item.isActive()).length === 0
    ) {
      setShowDialogPreloadTeam(true);
    }
  };

  const getUnsavedProjectCode = () => {
    let ret = props.formProject.getBillableInput().getValue() ? 'FA' : 'NFA';
    if (props.formProject.getStartValue()) {
      ret += props.formProject.getStartValue().toFormat('yyyy');
    }
    return ret + '-X';
  };

  const getCollaboratorsInSelectedNode = React.useCallback(async () => {
    const node = nodeInput.getValue();
    const res: Collaborator[] = [];

    if (node) {
      for (const collaborator of props.collaboratorsList) {
        if (await collaborator.nodeIs(node)) {
          res.push(collaborator);
        }
      }
    }

    setCollaboratorsInSelectedNode(res);
  }, [nodeInput.getValue(), props.collaboratorsList]);

  React.useEffect(() => {
    getCollaboratorsInSelectedNode();
  }, [getCollaboratorsInSelectedNode]);

  const preloadTeam = (confirm: boolean) => {
    if (confirm) {
      const colList = collaboratorsInSelectedNode;
      colList.forEach((col) => {
        const toAdd = CollaboratorTeam.initializeFromColaborador(col);

        props.formProject.getProjectTeamForm().getInputProjectTeamItems().add(toAdd);
      });

      props.onBlurCustomInput(props.formProject.getProjectTeamForm().getInputProjectTeamItems());
      props.onChangeProjectTeam(
          props.formProject
              .getProjectTeamForm()
              .getInputProjectTeamItems()
              .getValue()
              .filter((item) => item.isActive())
      );
    }

    setShowDialogPreloadTeam(false);
  };

  const activateProject = async () => {
    await props.system.getProjectSystem().reactivate(props.formProject.getIdInput().getValue());
    setProjectStatus('En proceso');
    props.courier.messageSuccess(t('Proyecto activado exitosamente'));
  };

  const deactivateProject = async () => {
    await props.system.getProjectSystem().deactivate(props.formProject.getIdInput().getValue());
    setProjectStatus('Inactivo');
    props.courier.messageSuccess(t('Proyecto desactivado exitosamente'));
  };

  const finalizeProject = async () => {
    setProjectStatus('Finalizado');
    props.courier.messageSuccess(t('Proyecto finalizado exitosamente'));
  };

  const GlobalNavButtonIcon: IIconProps = { iconName: 'GlobalNavButton' };

  const contextualMenuItems = [
    {
      key: 'activate',
      text: t('Activate'),
      onClick: () => {
        activateProject();
      },
      iconProps: { iconName: 'ActivateOrders' },
      className: projectStatus === t('En proceso') ? 'hide-project-btn' : '',
    },
    {
      key: 'deactivate',
      text: t('Deactivate'),
      onClick: () => {
        deactivateProject();
      },
      iconProps: { iconName: 'DeactivateOrders' },
      className: projectStatus === t('Inactivo') ? 'hide-project-btn' : '',
    },
    {
      key: 'finalize',
      text: t('Finalize'),
      onClick: () => {
        finalizeProject();
      },
      iconProps: { iconName: 'Completed' },
      className: projectStatus === t('Finalizado') ? 'hide-project-btn' : '',
    },
  ];

  const menuProps: IContextualMenuProps = {
    shouldFocusOnMount: true,
    items: contextualMenuItems,
  };

  return (
      <div className="ms-Grid bg-white-h100 ms-depth-4" dir="ltr">
        <div className="ms-Grid-row padding-1">
          <div className="ms-Grid-col ms-sm12">
            <div className="ms-Grid-row ">
              <div className="ms-Grid-col ms-sm6 ">
                <Text variant={'large'} className={'project-config-section-title'}>
                  <Icon iconName="Teamwork" className="text-icon" />
                  {props.formProject.getCodeInput().getValue()
                      ? props.formProject.getCodeInput().getValue()
                      : getUnsavedProjectCode()}
                </Text>
              </div>
              <div className="ms-Grid-col ms-sm6 ">
                {!props.formProject.isNew() && <ProjectStatus status={projectStatus} />}
                {!props.formProject.isNew() && (
                    <IconButton
                        className="float-right"
                        iconProps={GlobalNavButtonIcon}
                        title="Opciones de actividad"
                        ariaLabel="GlobalNavButton"
                        menuProps={menuProps}
                    />
                )}
              </div>
            </div>

            <div className="ms-Grid-row mt-2">
              <div className="ms-Grid-col ms-sm6">
                <InputField
                    input={props.formProject.getNameInput()}
                    label="Nombre del proyecto"
                    onBlur={props.onBlurCustomInput}
                />
              </div>
            </div>

            <div className="ms-Grid-row mt-2">
              <InputFieldForStartEnd
                  inputStart={props.formProject.getStartInput()}
                  inputEnd={props.formProject.getEndInput()}
                  startLabel="Vigencia - Inicio"
                  endLabel="Vigencia - Fin"
                  onBlur={props.onBlurCustomInput}
              />
            </div>
            <div className="ms-Grid-row mt-2">
              <div className="ms-Grid-col ms-sm12 ">
                <OrganizationChartDropdown
                    // label={lastLevelNode ? lastLevelNode.getName() : ''}
                    label={t('Jefatura')}
                    options={availableNodes}
                    autoComplete={'off'}
                    onChange={(node) => {
                      const input = nodeInput;
                      input.setValue(node);
                      input.onBlur();
                      props.onBlurCustomInput(input);
                      onSelectNode(input);
                    }}
                    selectedKey={
                      props.formProject.getOrganizationNodeValue()
                          ? props.formProject.getOrganizationNodeValue()!.getId()
                          : undefined
                    }
                />
              </div>
            </div>
            <div className="ms-Grid-row mt-2">
              <div className="ms-Grid-col ms-sm6">
                <ToggleInput
                    input={props.formProject.getBillableInput()}
                    onChangeExtended={(checked) => props.onChangeBillable(checked)}
                    onBlur={props.onBlurCustomInput}
                    offText={'No facturable'}
                    onText={'Facturable'}
                    propsInput={{ disabled: !props.formProject.isNew() }}
                />
              </div>

              <div className="ms-Grid-col ms-sm6">
                {/* <ToggleInput
                input={props.formProject.getSettlementInput()}
                onBlur={props.onBlurCustomInput}
                offText={'Liquidacion indirecta'}
                onText={'Liquidacion directa'}
                propsInput={{ disabled: !props.formProject.isNew(), styles: { container: { color: 'red' } } }}
              /> */}
              </div>
            </div>
            <div className="ms-Grid-row mt-2">
              <div className="ms-Grid-col ms-sm6">
                <ToggleInput
                    input={props.formProject.getConfidentialDocumentInput()}
                    onBlur={props.onBlurCustomInput}
                    offText={'Documentos abiertos por defecto'}
                    onText={'Documentos confidenciales por defecto'}
                />
              </div>
            </div>
            <div className="ms-Grid-row mt-2">
              <div className="ms-Grid-col ms-sm12">
                <TextArea
                    input={props.formProject.getDescriptionInput()}
                    label="Descripción del proyecto"
                    onBlur={props.onBlurCustomInput}
                />
              </div>
            </div>
          </div>
          {props.formProject.getOrganizationNodeValue() && (
              <ConfirmPreloadTeam
                  show={showDialogPreloadTeam}
                  collaborators={collaboratorsInSelectedNode.length}
                  onClose={preloadTeam}
                  nodeName={props.formProject.getOrganizationNodeValue()!.getName()}
              />
          )}
        </div>
      </div>
  );
};
