import { Icon, Modal } from "@fluentui/react";
import HeaderCollaboratorCreate, {
  InformacionGeneral,
} from "../CollaboratorView/collaboratorCreateView";
import "./collaboratorCreate.css";
import { FieldValues, useForm } from "react-hook-form";
import TimeTaxSupportSystemClient from "../../../system";
import gererateUuid from "../../../common/uuid";
import { ProyectoEnlace } from "../CollaboratorView/createColComponents/ProyectoEnlace";
import { EstructuraOrganizacional } from "../CollaboratorView/createColComponents/EstructuraOrganizacional";
import { useState } from "react";

interface ICollaboratorcreate {
  setviewcreate: (data: number) => void;
  system: TimeTaxSupportSystemClient | undefined;
  setToastExit: (data: boolean) => void;
}
export interface IOrganizationNode {
  nor_id: string;
  nor_nombre: string;
  nor_nivel: string;
  nor_padre: string;
}

export interface ILisCargo {
  cargo_id: string;
  cargo_nombre: string;
  cargo_activo: string;
}

function CollaboratorCreate({
  setviewcreate,
  system,
  setToastExit,
}: ICollaboratorcreate) {
  const [showModal, setShowModal] = useState(false);
  const [toastError, setToasError] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const datos = async (data: FieldValues) => {
    try {
      const datos: string = gererateUuid();
      const response = await system?.createUser(data, datos);
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const HandlerSubmit = handleSubmit(async (data: FieldValues) => {
    setToasError(false);
    setShowModal(true);
    const response = await datos(data);
    console.log(response);
    if (response.statusCode === 200) {
      setToastExit(true);
      setTimeout(() => {
        setToastExit(false);
      }, 5000); 
      setviewcreate(0);
    }else{
      setToasError(true);
    }
    setShowModal(false);
  });

  return (
    <div>
      {toastError && (
        <div className="toast_container_collaborator_error">
          <div className="toast_message_collaborator_error">
            <Icon
              iconName="ErrorBadge"
              className="icon_toast_collaborator_error"
              style={{ fontSize: "17px" }}
            />
            Propject name be empty (CustomObjectExcetion)
          </div>
          <Icon iconName="Clear" onClick={() =>setToasError(false)} />
        </div>
      )}
      <form onSubmit={HandlerSubmit} className="collaborator_create">
        <header className="header_collaborator_create">
          <HeaderCollaboratorCreate setviewcreate={setviewcreate} errors={errors} setToasError={setToasError} />
        </header>
        <div className="body_form">
          <div className="body_nuevo_colaborador">
            <Icon iconName="AddFriend" style={{ fontSize: "20px" }} />{" "}
            <p>Nuevo colaborador:</p>
          </div>
          <div className="body_informacion_general">
            <InformacionGeneral
              watch={watch}
              register={register}
              errors={errors}
              setValue={setValue}
            />
          </div>
          <div className="body_estructura_organizacional">
            <EstructuraOrganizacional
              system={system}
              watch={watch}
              register={register}
              errors={errors}
              setValue={setValue}
            />
          </div>
          <div className="body_proyecto_enlace">
            <ProyectoEnlace
              watch={watch}
              register={register}
              errors={errors}
              setValue={setValue}
            />
          </div>
        </div>
        <Modal isOpen={showModal}>
          <div className="header_azul_modal"></div>
          <div className="collaborator_modal_header">
            <div className="loading-container">
              <p>Cargando</p>
              <div className="collaborator_linea_blanca"></div>
              <div className="collaborator_color_azul"></div>
              <p>Aguarda unos instantes</p>
            </div>
          </div>
        </Modal>
      </form>
    </div>
  );
}

export default CollaboratorCreate;
