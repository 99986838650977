class Environment {
  ttsBaseApiUri() {
    return `${this.ttsBaseUri()}${this.ttsApiRelativeUri()}`;
  }

  apiUrl(path: string) {
    const base = this.ttsBaseApiUri();
    return `${base}${path}`;
  }

  ttsBaseUri() {
    return this.retrieveRequired('REACT_APP_TTS_BASE_URL');
  }

  captchaKey() {
    return this.retrieveRequired('REACT_APP_RECAPTCHA_KEY');
  }

  captchaAction() {
    return this.retrieveRequired('REACT_APP_RECAPTCHA_ACTION');
  }

  corporateSignInUrl() {
    return this.retrieveRequired('REACT_APP_TTS_CORPORATE_SIGN_IN_URL');
  }

  private ttsApiRelativeUri() {
    return this.retrieveRequired('REACT_APP_TTS_API_RELATIVE_URL');
  }

  private retrieveRequired(key: string) {
    const value = process.env[key];
    if (value === undefined) {
      throw new Error(`${key} is not defined`);
    }
    return value;
  }
}

export default Environment;
