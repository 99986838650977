import { FieldValues } from 'react-hook-form';
import Environment from '../lib/Environment';
import XhrRequestHandler, { StatusResponseJSON } from '../lib/XhrRequestHandler';
import { Collaborator } from './collaborators/Collaborator';
import CollaboratorSystem, { ICollaborator } from './collaborators/CollaboratorSystem';
import { SystemDateParser } from './SystemDateParser';
import { User } from './User';
import { UserProfile } from './UserProfile';
import gererateUuid from '../common/uuid';
import { IList_cnih } from '../Components/Collaborator/CollaboratorView/updateCollaborator/InformacionGeneral';
import { IEstructuraOrganizacional } from '../Components/Collaborator/CollaboratorView/updateCollaborator/interface';

export interface IUserProfile {
  per_id: string;
  per_nombre: string;
  per_nemotecnico: string;
}

export interface IQRurl {
  qrUrl: string;
}

export interface ILoggedUser extends IUser {
  representing: ICollaborator;
  perfil: IUserProfile;
}

export interface IUser {
  us_id: string;
  us_email: string;
  us_nombre: string;
}

class AuthenticationSystem {
  private readonly loginBase = '/loginDVM';
  private loggedUser: User | undefined;

  constructor(
      private requestHandler: XhrRequestHandler,
      private environment: Environment,
      private dateParser: SystemDateParser,
      private collaboratorsSystem: CollaboratorSystem
  ) {
    this.loggedUser = undefined;
  }

  async loggedInUser(): Promise<User> {
    const data = await this.requestHandler.get<ILoggedUser>(
        `${this.loginBase}/isLoggedIn?tz=${encodeURIComponent(this.dateParser.getUserTimezone())}`
    );

    this.loggedUser = this.userFromILoggedUser(data);

    return this.loggedUser;
  }

//creado para formulario agregar colaboradores 
  async createUser(data:FieldValues,id:string) {
		return await this.requestHandler.newColaborator(data,id);
}
 
 async getSelects(nor_nivel?: string,nor_padre?:string){
   return await this.requestHandler.organizationNode(nor_nivel,nor_padre);
 }

 async lisCargo(){
  return await this.requestHandler.listCargo();
 }

//creado para formulario agregar colaboradores 
  async signIn(
      email: string,
      password: string,
      token: string
  ): Promise<StatusResponse<User | IQRurl | null>> {
    const credentials = {
      email,
      password,
      token,
    };
    this.requestHandler.clearCache();
    const response = await this.requestHandler.post<StatusResponseJSON<ILoggedUser | IQRurl | null>>(
        `${this.loginBase}/loginWithCredentials`,
        credentials
    );

    if (response.statusCode === 200) {
      this.loggedUser = this.userFromILoggedUser(response.data as ILoggedUser);
      return new StatusResponse(response.statusCode, this.userFromILoggedUser(response.data as ILoggedUser));
    }

    return new StatusResponse(response.statusCode, response.data as IQRurl | null);
  }

  async validateCode2FA(code) {
    const data = await this.requestHandler.post<ILoggedUser>(`${this.loginBase}/validate-totp-code`, {
      code: code,
    });
    return this.userFromILoggedUser(data);
  }

  async updateColaboratorUser(datos){

    const data = {
      data :datos,
      uuid:gererateUuid()
    }
     const result = await this.requestHandler.post(`${this.loginBase}/UpdateCollaborador`,data);
     return result
  }

  async getListProyectoEnlace(email:string){
    return this.requestHandler.ListProyectoEnlace(email);
  }

  async DeleteUNProyectoEnlace(id:string){
     return this.requestHandler.DeleteProyectforId(id);
  }

  async modificarOneProyectoEnlace(datos:IList_cnih){
    return this.requestHandler.modificarOneProyectoEnlace(datos);
  }

  async CrearOneProyectoEnlace(datos:IList_cnih,email:string){
    return this.requestHandler.CrearOneProyectoEnlace(datos,email)
  }

  async CreateEstructuraOranizacional(datos:IEstructuraOrganizacional){
    return this.requestHandler.CreateEstructuraOranizacional(datos);
  }

  async UpdateEstructuraOranizacional(datos:IEstructuraOrganizacional){
    return this.requestHandler.UpdateEstructuraOranizacional(datos);
  }

  async ListEstructuraOrganizacional(id:string){
    return this.requestHandler.ListEstructuraOrganizacional(id);
  }

  async DeleteEstructuraOrganizacional(id:string){
    return this.requestHandler.DeleteEstructuraOrganizacional(id);
  }

  corporateSingIn() {
    this.requestHandler.clearCache();
    this.requestHandler.navigateTo(this.environment.corporateSignInUrl());
  }

  signOut(returnTo: string) {
    this.requestHandler.clearCache();
    this.requestHandler.navigateTo(`${this.loginBase}/logout?returnTo=${returnTo}`);
  }

  private userFromILoggedUser({ representing, perfil, ...user }: ILoggedUser) {
    const collaborator = Collaborator.fromICollaborator(this.collaboratorsSystem, representing);
    const profile = UserProfile.fromIUserProfile(perfil);

    return User.fromIUser(collaborator, profile, user);
  }
}

class StatusResponse<T> {
  constructor(private statusCode: number, private data: T) {}

  getStatusCode() {
    return this.statusCode;
  }

  getData() {
    return this.data;
  }

  requireTOTPCode() {
    return this.statusCode === 204;
  }

  shouldShowQRAuth() {
    return this.statusCode === 205;
  }

  isOk() {
    return this.statusCode === 200;
  }
}

export default AuthenticationSystem;
