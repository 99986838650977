import React from 'react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  createSearchParams,
  useSearchParams,
} from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Base from './Components/Layout/Base';
import Home from './Components/Home';
import Tutorial from './Components/Tutorial';
import NotFound from './Components/NotFound';
import ActivityView from './Components/Activity/ActivityView';
import CompanyTreeView from './Components/CompanyTree/CompanyTreeView';
import ProjectView from './Components/Proyectos/ProjectView';
import { ProjectConfiguration } from './Components/Proyectos/ProjectConfiguration/ProjectConfiguration';
import CalendarView from './Components/Calendar/CalendarView';
import DefenseFileContainer from './Components/Wizard/Archivos-Defensa/DefenseFileContainer';
import { BasicInputsView } from './Components/Common/BasicInputsView';
import { ProjectList } from './Components/Proyectos/List/ProjectList';
import { ActivityList } from './Components/Activity/ActivityList';
import { ArchivosDefensaList } from './Components/Archivos-defensa/Defense-file/ArchivosDefensaList';
import ArchivosDefensaView from './Components/Archivos-defensa/Defense-file/ArchivosDefensaView';
import { SettlementsList } from './Components/Settlements/Indirect/SettlementsList';
import SettlementsView from './Components/Settlements/Indirect/SettlementsView';
import { CostPerHourList } from './Components/Templates/CostPerHourList';
import CostPerHourView from './Components/Templates/CostPerHourView';
import { DocumentList } from './Components/Document/List/DocumentList';
import { NotificationList } from './Components/Notifications/List/NotificationList';
import { FormExample } from './Components/Common/FormExample';
import { PanelProjectsProgress } from './Components/Panel/PanelProjectsProgress/PanelProjectsProgress';
import { PanelCollaborators } from './Components/Panel/PanelCollaborators/PanelCollaborators';
import { BlockPeriods } from './Components/BlockPeriods/BlockPeriods';

import BasicView from './Components/Layout/BasicView';
import LoginView from './Components/LoginView';
import RecoverPasswordView from './Components/RecoverPassword/RecoverPasswordView';
import { CollaboratorList } from './Components/Collaborator/CollaboratorList';
import CollaboratorView from './Components/Collaborator/CollaboratorView/CollaboratorView';
import {
  routes,
  routesAvailableForDirective,
  routesAvailableForPowerUser,
  routesAvailableForStandardUser,
} from './common/constants';
import useAsyncDataFull from './common/custom-hooks/useAsyncDataFull';
import TimeTaxSupportSystemClient from './system/TimeTaxSupportSystemClient';
import { User } from './system/User';
import Environment from './lib/Environment';
import MessageCourier from './lib/MessageCourier';
import { ProjectPanel } from './Components/Proyectos/ProjectPanel/ProjectPanel';
import BasicBlockUI from './Components/Common/BasicBlockUI';
import { ProjectRequiredDocument } from './Components/Proyectos/ProjectRequiredDocument/ProjectRequiredDocument';
import ControlDocument from './Components/ControlDocument/ControlDocument';
import { PanelCompanies } from './Components/Panel/PanelCompanies/PanelCompanies';
import { MassiveActivitiesUploadAssistant } from './Components/MassiveActivitiesUploadAssistant/MassiveActivitiesUploadAssistant';
import { MassiveActivitiesChecker } from './Components/MassiveActivitiesChecker/MassiveActivitiesChecker';
import { MassiveDistributionCorrector } from './Components/MassiveDistributionCorrector/MassiveDistributionCorrector';
import SettlementDetail from './Components/Settlements/Indirect/SettlementDetail';
import DirectSettlementDetail from './Components/Settlements/Direct/DirectSettlementDetail';
import { ReportingSettlementHoursCompaniesDE } from './Components/Reporting/ReportingSettlementHoursCompaniesDE';
import { ReportingSettlementHoursSectorDE } from './Components/Reporting/ReportingSettlementHoursSectorDE';
import { ReportingHoursProjects } from './Components/Reporting/ReportingHoursProjects';

import { ReportingSettlementPreliquidation } from './Components/Reporting/ReportingSettlementPreliquidation';
import { PanelOwn } from './Components/Panel/PanelOwn/PanelOwn';
import { InsufficientpermissionsDialog } from './Components/Common/InsufficientpermissionsDialog';
import { DetailListStatus } from './Components/Common/DetailsList/DetailListStatus';
import Maintenance from './Components/Maintenance/Maintenance';
import SystemConfiguration from './system/configuration/SystemConfiguration';
import { t } from 'i18next';
import DirectSettlementsView from './Components/Settlements/Direct/DirectSettlementsView';
import { DirectSettlementsList } from './Components/Settlements/Direct/DirectSettlementsList';
import { MassiveDefenseList } from './Components/Archivos-defensa/Massive-Defense/MassiveDefenseList';
import { MailList } from './Components/Mail/List/MailList';
import MassiveDefenseDetail from './Components/Archivos-defensa/Massive-Defense/MassiveDefenseDetail';
import MassiveDefenseView from './Components/Archivos-defensa/Massive-Defense/MassiveDefenseView';
import LicensesView from './Components/Licenses/LicensesView';
import { LicensesList } from './Components/Licenses/LicensesList';
import LicensesDetail from './Components/Licenses/LicensesDetail';
import { ProjectMassiveMailInspector } from './Components/Proyectos/ProjectMassiveMailInspector/ProjectMassiveMailInspector';
import { MessageReceiver } from './lib/MessageReceiver';
import XhrRequestError from './lib/XhrRequestError';
import XhrErrorListener from './lib/XhrErrorListener';
import { ReportingDocsControl } from './Components/Reporting/ReportingDocsControl';
import { ReportingMMI } from './Components/Reporting/ReportingMMI';
import { PeriodDatesBlocked } from './system/period_dates_blocked/PeriodDatesBlocked';
import PeriodDatesBlockedSystem from './system/period_dates_blocked/PeriodDatesBlockedSystem';
import CostPerHourDetail from './Components/Templates/CostPerHourDetail';
import { ProjectDistributionByPeriodWizard } from './Components/Proyectos/ProjectDistributionByPeriod/ProjectDistributionByPeriodWizard';
import { ProjectDistributionByPeriodViewer } from './Components/Proyectos/ProjectDistributionByPeriod/ProjectDistributionByPeriodViewer';
import { ReportingBillableDistribution } from './Components/Reporting/ReportingBillableDistribution';

interface IRouterContextProps {
  system: TimeTaxSupportSystemClient;
  environment: Environment;
  courier: MessageCourier;
}

const defaultConfig = SystemConfiguration.default();
const defaultPeriods = PeriodDatesBlockedSystem.default();
let timeout: any = null;

class ErrorListener implements XhrErrorListener {
  constructor(private onAuthenticationError: (error: XhrRequestError) => void) {}

  receiveError(error: XhrRequestError): void {
    console.log('error :>> **', error);
    if (error.isAuthenticationRequiredError()) {
      this.onAuthenticationError(error);
    }
  }
}

const AppInRouterContext = (props: IRouterContextProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [block, setBlock] = React.useState(false);
  const [showInsufficentPermissionsDialog, setShowInsufficentPermissionsDialog] = React.useState(false);
  const [us, setUs] = React.useState<User>();
  const [config, setConfig] = React.useState<SystemConfiguration>(defaultConfig);
  const [periodsBlocked, setPeriodsBlocked] = React.useState<PeriodDatesBlockedSystem>(defaultPeriods);
  const [errorListener, setErrorListener] = React.useState<ErrorListener>();

  const changeBlockUI = (shouldBlock: boolean) => {
    setBlock(shouldBlock);
  };

  const { data, loaded, loading } = useAsyncDataFull(
      () => props.system.loggedInUser(),
      [props.system, location.pathname],
      undefined,
      changeBlockUI
  );

  const loadedUser = React.useMemo(() => {
    return loaded && !loading;
  }, [loaded, loading]);

  const getConfig = React.useCallback(async () => {
    const c = await props.system.configuration();
    setConfig(c);
  }, [props.system]);

  const getPeriodsBlocked = React.useCallback(async () => {
    const p = await props.system.periodsBlocked();
    setPeriodsBlocked(PeriodDatesBlockedSystem.build(p));
  }, [props.system]);

  React.useEffect(() => {
    if (loadedUser) {
      setUs(data);
      if (data) {
        getConfig();
        getPeriodsBlocked();
      }
    }
  }, [data, loadedUser, getConfig, getPeriodsBlocked]);

  React.useEffect(() => {
    setErrorListener((e) => {
      if (e) {
        props.system.unsubscribe(e);
      }
      const newListener = new ErrorListener((_error) => {
        if (location.pathname !== routes.login) {
          navigate({
            pathname: routes.login,
            search: createSearchParams({ to: location.pathname + location.search }).toString(),
          });
        }
      });
      props.system.subscribe(newListener);
      return newListener;
    });
  }, [navigate, location, props.system]);

  React.useEffect(() => {
    if (location.pathname !== routes.login && location.pathname !== routes.recover_password) {
      showInsufficentPermissions(location.pathname);
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      if (!us && loadedUser) {
        timeout = setTimeout(() => {
          navigate(routes.login);
        }, 100);
      }
    }
  }, [us, loadedUser, navigate, location.pathname]);

  const onLogin = (loggedInUser: User) => {
    setUs(loggedInUser);
    DetailListStatus.cleanFilters();

    const returnTo = searchParams.get('to');
    setTimeout(() => navigate(returnTo ? returnTo : routes.calendar), 500);
  };

  const showInsufficentPermissions = (pathname: string) => {
    let _pathname = pathname.split('/')[1];
    if (_pathname)
      if (_pathname && _pathname !== '/') {
        if (us) {
          let isAvailableRoute = false;

          if (us.getProfile().isStandardUser()) {
            isAvailableRoute = !!routesAvailableForStandardUser.find((route) => {
              return getRouteName(route) === _pathname;
            });
          }

          if (us.getProfile().isDirectiveUser()) {
            isAvailableRoute = !!routesAvailableForDirective.find((route) => {
              return getRouteName(route) === _pathname;
            });
          }

          if (us.getProfile().isPowerUser() || us.getProfile().isAdminUser()) {
            isAvailableRoute = !!routesAvailableForPowerUser.find((route) => {
              return getRouteName(route) === _pathname;
            });
          }

          if (!isAvailableRoute) {
            setShowInsufficentPermissionsDialog(true);
          }
        }
      }
  };

  const onCloseInsufficentPermissionsDialog = () => {
    setShowInsufficentPermissionsDialog(false);
    navigate(routes.calendar);
  };

  const getRouteName = (route: string) => {
    let s = route;
    if (s[0] === '/') {
      s = route.substring(1);
    }
    return s;
  };

  const getVideoName = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('video');
  };

  return (
      <>
        <BasicBlockUI blockUI={block} />
        <InsufficientpermissionsDialog
            onClose={onCloseInsufficentPermissionsDialog}
            show={showInsufficentPermissionsDialog}
        ></InsufficientpermissionsDialog>
        <Routes>
          {us && (
              <Route
                  path="/"
                  element={
                    <Base
                        courier={props.courier}
                        system={props.system}
                        user={us}
                        changeBlockUI={changeBlockUI}
                        config={config}
                        periodsBlocked={periodsBlocked}
                    />
                  }
              >
                <Route
                    index
                    element={
                      <Home courier={props.courier} system={props.system} user={us} changeBlockUI={changeBlockUI} />
                    }
                />
                <Route path={routes.help} element={<Tutorial video={getVideoName()} />} />
                <Route
                    path={routes.documents}
                    element={
                      <DocumentList
                          courier={props.courier}
                          system={props.system}
                          user={us}
                          changeBlockUI={changeBlockUI}
                      />
                    }
                />

                <Route
                    path={routes.bloqueo_periodos}
                    element={
                      <BlockPeriods
                          courier={props.courier}
                          system={props.system}
                          user={us}
                          changeBlockUI={changeBlockUI}
                      />
                    }
                />

                <Route
                    path={routes.mails}
                    element={
                      <MailList
                          courier={props.courier}
                          system={props.system}
                          user={us}
                          changeBlockUI={changeBlockUI}
                      />
                    }
                />

                <Route
                    path={routes.notifications}
                    element={
                      <NotificationList
                          courier={props.courier}
                          system={props.system}
                          user={us}
                          changeBlockUI={changeBlockUI}
                          config={config}
                          periodsBlocked={periodsBlocked}
                      />
                    }
                />
                <Route
                    path={routes.activity_list}
                    element={
                      <ActivityList
                          config={config}
                          courier={props.courier}
                          system={props.system}
                          user={us}
                          changeBlockUI={changeBlockUI}
                          periodsBlocked={periodsBlocked}
                      />
                    }
                />

                <Route
                    path={routes.direct_settlement_list}
                    element={
                      <DirectSettlementsList
                          changeBlockUI={changeBlockUI}
                          system={props.system}
                          courier={props.courier}
                      />
                    }
                />
                <Route path={routes.direct_settlement}>
                  <Route
                      path=":id"
                      element={
                        <DirectSettlementDetail
                            system={props.system}
                            courier={props.courier}
                            changeBlockUI={changeBlockUI}
                        />
                      }
                  />
                </Route>

                <Route
                    path={routes.settlement_list}
                    element={
                      <SettlementsList
                          changeBlockUI={changeBlockUI}
                          system={props.system}
                          courier={props.courier}
                      />
                    }
                />
                <Route path={routes.settlement}>
                  <Route
                      path=":id"
                      element={<SettlementDetail system={props.system} courier={props.courier} />}
                  />
                </Route>
                <Route
                    path={routes.settlementView}
                    element={<SettlementsView system={props.system} courier={props.courier} />}
                />
                <Route
                    path={routes.directSettlementView}
                    element={
                      <DirectSettlementsView
                          changeBlockUI={changeBlockUI}
                          system={props.system}
                          courier={props.courier}
                      />
                    }
                />

                <Route
                    path={routes.cost_per_hour_list}
                    element={
                      <CostPerHourList
                          changeBlockUI={changeBlockUI}
                          system={props.system}
                          courier={props.courier}
                      />
                    }
                />
                <Route
                    path={routes.cost_per_hour_view}
                    element={
                      <CostPerHourView
                          system={props.system}
                          courier={props.courier}
                          changeBlockUI={changeBlockUI}
                          user={us}
                      />
                    }
                />
                <Route
                    path={routes.cost_per_hour_view}
                    element={
                      <CostPerHourView
                          changeBlockUI={changeBlockUI}
                          system={props.system}
                          courier={props.courier}
                          user={us}
                      />
                    }
                />
                <Route path={routes.activity} element={<ActivityView />} />
                <Route
                    path={routes.defense_file_assistant}
                    element={
                      <DefenseFileContainer
                          system={props.system}
                          courier={props.courier}
                          changeBlockUI={changeBlockUI}
                      />
                    }
                />
                <Route path={routes.defense_file}>
                  <Route
                      index
                      element={
                        <ArchivosDefensaList
                            courier={props.courier}
                            changeBlockUI={changeBlockUI}
                            system={props.system}
                        />
                      }
                  />
                  <Route
                      path=":id"
                      element={
                        <ArchivosDefensaView
                            system={props.system}
                            courier={props.courier}
                            changeBlockUI={changeBlockUI}
                        />
                      }
                  />
                </Route>
                <Route
                    path={routes.massive_defense_view}
                    element={
                      <MassiveDefenseView
                          system={props.system}
                          courier={props.courier}
                          changeBlockUI={changeBlockUI}
                      />
                    }
                />
                <Route path={routes.massive_defense_list}>
                  <Route
                      index
                      element={
                        <MassiveDefenseList
                            courier={props.courier}
                            changeBlockUI={changeBlockUI}
                            system={props.system}
                        />
                      }
                  />
                  <Route
                      path=":id"
                      element={
                        <MassiveDefenseDetail
                            system={props.system}
                            courier={props.courier}
                            changeBlockUI={changeBlockUI}
                        />
                      }
                  />
                </Route>
                <Route
                    path={routes.collaborator_list}
                    element={
                      <CollaboratorList
                          changeBlockUI={changeBlockUI}
                          system={props.system}
                          courier={props.courier}
                      />
                    }
                />
                <Route
                    path={routes.company_tree}
                    element={
                      <CompanyTreeView
                          system={props.system}
                          courier={props.courier}
                          changeBlockUI={changeBlockUI}
                          user={us}
                      />
                    }
                />

                <Route
                    path={routes.project_list}
                    element={
                      <ProjectList
                          system={props.system}
                          courier={props.courier}
                          user={us}
                          changeBlockUI={changeBlockUI}
                      />
                    }
                />

                <Route path={routes.project_config}>
                  <Route
                      path=":id"
                      element={
                        <ProjectConfiguration
                            system={props.system}
                            courier={props.courier}
                            blockUi={changeBlockUI}
                            user={us}
                        />
                      }
                  />
                </Route>

                <Route path={routes.edit_distribution_by_period}>
                  <Route
                      path=":id"
                      element={
                        <ProjectDistributionByPeriodWizard
                            system={props.system}
                            courier={props.courier}
                            user={us}
                            changeBlockUI={changeBlockUI}
                            config={config}
                            periodsBlocked={periodsBlocked}
                        />
                      }
                  />
                </Route>

                <Route path={routes.view_distribution_by_period}>
                  <Route
                      path=":id"
                      element={
                        <ProjectDistributionByPeriodViewer
                            system={props.system}
                            courier={props.courier}
                            user={us}
                            changeBlockUI={changeBlockUI}
                            config={config}
                            periodsBlocked={periodsBlocked}
                        />
                      }
                  />
                </Route>

                <Route path={routes.project_docs}>
                  <Route
                      path=":id"
                      element={
                        <ProjectRequiredDocument
                            system={props.system}
                            courier={props.courier}
                            user={us}
                            changeBlockUI={changeBlockUI}
                        />
                      }
                  />
                </Route>
                <Route path={routes.project_panel}>
                  <Route path=":id" element={<ProjectPanel system={props.system} courier={props.courier} />} />
                </Route>

                <Route
                    path={`${routes.reportsCompany}`}
                    element={
                      <ReportingSettlementHoursCompaniesDE
                          changeBlockUI={changeBlockUI}
                          system={props.system}
                          courier={props.courier}
                      />
                    }
                />

                <Route
                    path={`${routes.reportsProjectDocs}`}
                    element={
                      <ReportingDocsControl
                          user={us}
                          changeBlockUI={changeBlockUI}
                          system={props.system}
                          courier={props.courier}
                      />
                    }
                />

                <Route
                    path={`${routes.reportsMMI}`}
                    element={
                      <ReportingMMI
                          user={us}
                          changeBlockUI={changeBlockUI}
                          system={props.system}
                          courier={props.courier}
                      />
                    }
                />

                <Route
                    path={`${routes.reportsBillableDistribution}`}
                    element={
                      <ReportingBillableDistribution
                          user={us}
                          changeBlockUI={changeBlockUI}
                          system={props.system}
                          courier={props.courier}
                      />
                    }
                />

                <Route
                    path={routes.reportsSector}
                    element={
                      <ReportingSettlementHoursSectorDE
                          changeBlockUI={changeBlockUI}
                          system={props.system}
                          user={us}
                          courier={props.courier}
                      />
                    }
                />

                <Route
                    path={routes.reportsProject}
                    element={
                      <ReportingHoursProjects
                          changeBlockUI={changeBlockUI}
                          system={props.system}
                          user={us}
                          courier={props.courier}
                      />
                    }
                />

                <Route
                    path={routes.preliquidation}
                    element={
                      <ReportingSettlementPreliquidation
                          changeBlockUI={changeBlockUI}
                          system={props.system}
                          courier={props.courier}
                          user={us}
                      />
                    }
                />

                <Route path={routes.control_doc}>
                  <Route
                      path=":proId/:id"
                      element={<ControlDocument system={props.system} courier={props.courier} />}
                  />
                </Route>

                <Route path={routes.project}>
                  <Route
                      index
                      element={
                        <ProjectView
                            system={props.system}
                            courier={props.courier}
                            user={us}
                            changeBlockUI={changeBlockUI}
                            config={config}
                            periodsBlocked={periodsBlocked}
                        />
                      }
                  />
                  <Route
                      path=":id"
                      element={
                        <ProjectView
                            system={props.system}
                            courier={props.courier}
                            user={us}
                            changeBlockUI={changeBlockUI}
                            config={config}
                            periodsBlocked={periodsBlocked}
                        />
                      }
                  />
                  {/* <Route
                  path=":id:/showPanel?"
                  element={
                    <ProjectView
                      changeBlockUI={changeBlockUI}
                      system={props.system}
                      courier={props.courier}
                      user={user}
                    />
                  }
                /> */}
                </Route>

                <Route path={routes.massive_mail_inspector}>
                  {/* <Route
                index
                element={
                  <ProjectView
                    system={props.system}
                    courier={props.courier}
                    user={us}
                    changeBlockUI={changeBlockUI}
                    config={config}
                  />
                }
              /> */}
                  <Route
                      path=":id"
                      element={
                        <ProjectMassiveMailInspector
                            system={props.system}
                            courier={props.courier}
                            user={us}
                            changeBlockUI={changeBlockUI}
                            config={config}
                        />
                      }
                  />
                </Route>

                <Route path={routes.collaborator}>
                  <Route
                      index
                      element={
                        <CollaboratorView
                            system={props.system}
                            courier={props.courier}
                            changeBlockUI={changeBlockUI}
                            user={us}
                        />
                      }
                  />

                  <Route
                      path=":collaboratorId"
                      element={
                        <CollaboratorView
                            system={props.system}
                            courier={props.courier}
                            changeBlockUI={changeBlockUI}
                            user={us}
                        />
                      }
                  />
                </Route>

                <Route path={routes.cost_per_hour_detail}>
                  <Route
                      index
                      element={
                        <CostPerHourDetail
                            system={props.system}
                            courier={props.courier}
                            changeBlockUI={changeBlockUI}
                            // user={us}
                        />
                      }
                  />

                  <Route
                      path=":costoPorHoraId"
                      element={
                        <CostPerHourDetail
                            system={props.system}
                            courier={props.courier}
                            changeBlockUI={changeBlockUI}
                            // user={us}
                        />
                      }
                  />
                </Route>

                <Route
                    path={routes.calendar}
                    element={
                      <CalendarView
                          system={props.system}
                          courier={props.courier}
                          config={config}
                          user={us}
                          changeBlockUI={changeBlockUI}
                          periodsBlocked={periodsBlocked}
                      />
                    }
                />

                <Route
                    path={routes.dashboard}
                    element={
                      <PanelProjectsProgress
                          system={props.system}
                          courier={props.courier}
                          user={us}
                          changeBlockUI={changeBlockUI}
                      />
                    }
                />

                <Route
                    path={routes.activity_import}
                    element={
                      <MassiveActivitiesUploadAssistant
                          system={props.system}
                          courier={props.courier}
                          user={us}
                          changeBlockUI={changeBlockUI}
                      />
                    }
                />

                <Route
                    path={routes.activity_checker}
                    element={
                      <MassiveActivitiesChecker
                          system={props.system}
                          courier={props.courier}
                          user={us}
                          changeBlockUI={changeBlockUI}
                          config={config}
                          periodsBlocked={periodsBlocked}
                      />
                    }
                />

                <Route
                    path={routes.distribution_corrector}
                    element={
                      <MassiveDistributionCorrector
                          system={props.system}
                          courier={props.courier}
                          user={us}
                          changeBlockUI={changeBlockUI}
                          config={config}
                          periodsBlocked={periodsBlocked}
                      />
                    }
                />

                <Route
                    path={routes.dashboard_own}
                    element={
                      <PanelOwn
                          system={props.system}
                          courier={props.courier}
                          user={us}
                          changeBlockUI={changeBlockUI}
                      />
                    }
                />

                <Route
                    path={routes.dashboard_empresas}
                    element={
                      <PanelCompanies
                          system={props.system}
                          courier={props.courier}
                          user={us}
                          changeBlockUI={changeBlockUI}
                      />
                    }
                />

                <Route
                    path={routes.dashboard_colaboradores}
                    element={
                      <PanelCollaborators
                          system={props.system}
                          courier={props.courier}
                          user={us}
                          changeBlockUI={changeBlockUI}
                      />
                    }
                />

                <Route
                    path={routes.basic_inputs}
                    element={<BasicInputsView system={props.system} courier={props.courier} />}
                />

                <Route path={routes.licenses}>
                  <Route
                      index
                      element={
                        <LicensesList
                            user={us}
                            system={props.system}
                            courier={props.courier}
                            changeBlockUI={changeBlockUI}
                        />
                      }
                  />
                  <Route
                      path=":id"
                      element={
                        <LicensesDetail
                            system={props.system}
                            courier={props.courier}
                            changeBlockUI={changeBlockUI}
                        />
                      }
                  />
                </Route>
                <Route path={routes.licences_config}>
                  <Route
                      index
                      element={
                        <LicensesView system={props.system} courier={props.courier} changeBlockUI={changeBlockUI} />
                      }
                  />
                </Route>

                <Route path={routes.form_example} element={<FormExample />} />
                <Route path="*" element={<NotFound />} />
              </Route>
          )}

          <Route
              path={routes.recover_password}
              element={
                <GoogleReCaptchaProvider
                    reCaptchaKey={props.environment.captchaKey()}
                    language={'es'}
                    scriptProps={{ async: false, appendTo: 'head' }}
                >
                  <RecoverPasswordView
                      system={props.system}
                      courier={props.courier}
                      tokenAction={props.environment.captchaAction()}
                  />
                </GoogleReCaptchaProvider>
              }
          />

          <Route
              path={routes.login}
              element={
                <GoogleReCaptchaProvider
                    reCaptchaKey={props.environment.captchaKey()}
                    language={'es'}
                    scriptProps={{ async: false, appendTo: 'head' }}
                >
                  <LoginView
                      system={props.system}
                      tokenAction={props.environment.captchaAction()}
                      onLogin={onLogin}
                      changeBlockUI={changeBlockUI}
                  />
                </GoogleReCaptchaProvider>
              }
          />

          <Route path={routes.maintenance} element={<Maintenance></Maintenance>} />
        </Routes>
        {!loadedUser && (
            <section className="section-container">
              <BasicView title="">{t('Cargando... aguarda unos instantes')}</BasicView>
            </section>
        )}
      </>
  );
};

export default AppInRouterContext;
