import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { IStackTokens } from '@fluentui/react/lib/Stack';
import { CARD_MODE, GRID_MODE, OPERATORS, TYPES } from '../../../common/list-utils';
import { NotificationGridList } from './NotificationGridList';
import { ListColumnFilter } from '../../Common/list-entities/ListColumnFilter';
import { CommandBarButton, Fabric, PrimaryButton, Stack, Text, TextField, Toggle } from '@fluentui/react';
// import './ActivityList.css';
import TimeTaxSupportSystemClient from '../../../system';
import {
  ContextualMenu,
  DefaultPalette,
  DetailsList,
  Dialog,
  DialogType,
  IColumn,
  ICommandBarItemProps,
  Icon,
  IDropdownOption,
  SelectionMode,
} from '@fluentui/react';
import useSystemCall from '../../../common/custom-hooks/useSystemCall';
import Project from '../../../system/projects/Project';
import MessageCourier from '../../../lib/MessageCourier';
import { User } from '../../../system/User';
import { ProjectRelatedDocument } from '../../../system/documents/ProjectRelatedDocument';
import DocumentView from '../../Proyectos/DocumentView';
import ViewWrapperWithCommandBar from '../../Layout/ViewWrapperWithCommandBar';
import { buttonStylesForProjects, defaultCommandBarButtonStyles } from '../../../styles/button-styles';
import { DatePicker, MarqueeSelection } from 'office-ui-fabric-react';
import { readFile } from 'fs';
import { DateTime } from 'luxon';
import { IIconProps, IStackStyles } from '@fluentui/react';
import { DateCustom } from '../../../lib/DateCustom';
import { NotificationsTable } from './NotificationsTable';
import { CalendarEventCallout } from '../../Calendar/CalendarEventCallout';
import { DetailListHeader } from '../../Common/DetailsList/DetailListHeader';
import { Calendar } from '../../../classes/Calendar/Calendar';
import { CalendarEvent } from '../../../classes/Calendar/CalendarEvent';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from '../../../common/constants';
import { useTranslation, Trans } from 'react-i18next';
import { getLocalizedStrings } from '../../../lib/LocalizedStringsForDatePicker';
import SystemConfiguration from '../../../system/configuration/SystemConfiguration';
import { Activity } from '../../../system/activity/Activity';
import PeriodDatesBlockedSystem from '../../../system/period_dates_blocked/PeriodDatesBlockedSystem';

const stackTokens: IStackTokens = { childrenGap: 20 };

interface INotificationListProps {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  user: User;
  changeBlockUI: (change: boolean) => void;
  eventData?: any;
  config: SystemConfiguration;
  periodsBlocked: PeriodDatesBlockedSystem;
}

export interface Notification {
  actividad: string | null;
  colaborador: string | null;
  not_activo: string;
  not_asunto: string;
  not_dest_colid: string;
  not_entidad: string;
  not_entidad_id: string;
  not_fecha: string;
  not_id: string;
  not_leido: boolean;
  not_leido_fecha: string;
  not_ori_colid: string | null;
  not_texto: string;
  not_tipo: string;
}

export const NotificationList = (props: INotificationListProps) => {
  const { t, i18n } = useTranslation();
  const [notificationsList, setNotificationsList] = React.useState<Notification[]>([]);
  const [notificationsFilteredList, setNotificationsFilteredList] = React.useState<Notification[] | null>(
      null
  );
  const [columns, setColumns] = React.useState<any>();
  const [announcedMessage, setAnnouncedMessage] = useState<any>();
  const [selectedNotifications, setSelectedNotifications] = useState<Notification[]>([]);
  // const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();
  const [businnesFilter, setBusinessFilter] = useState<string>('');
  const [textFilter, setTextFilter] = useState<string>('');
  const [activeFilter, setActiveFilter] = useState<string | null>();
  const [readFilter, setReadFilter] = useState<boolean | null>();
  const [dateFilter, setDateFilter] = useState<Date>();
  const [isCalloutVisible, setIsCalloutVisible] = useState<boolean>(true);
  const [fromDate, setFromDate] = React.useState<DateTime | undefined>(undefined);
  const [eventData, setEventData] = useState<any>();

  const calendar = React.useMemo(() => {
    return new Calendar(props.system.getActivitySystem());
  }, [props.system]);
  const calendarEvent = React.useMemo(() => {
    return new CalendarEvent();
  }, [props.system]);
  const [activeStart, setActiveStart] = React.useState<Date | undefined>(undefined);
  const [activeEnd, setActiveEnd] = React.useState<Date | undefined>(undefined);
  const [eventList, setEventList] = React.useState<any[]>([]);

  const [activity, setActivity] = React.useState<Activity | undefined>(undefined);
  const [showDialog, setShowDialog] = React.useState(false);

  const projects = useSystemCall(props.system, (system) => system.getProjects());
  const commonContext = useSystemCall(props.system, (system) =>
      system.getActivitySystem().getCommonContext()
  );

  // const eventData = React.useMemo(() => {
  //   return props.eventData;
  // }, [props.eventData]);

  // const targetId = useId(
  //   'target-' + eventData && eventData.event
  //     ? eventData.event._def.extendedProps.data.id.substring(0, 20)
  //     : ''
  // );
  const targetId = '';
  const labelId = '';
  const descriptionId = '';
  // const labelId = useId(
  //   'label-' + eventData && eventData.event ? eventData.event._def.extendedProps.data.id.substring(0, 20) : ''
  // );
  // const descriptionId = useId(
  //   'description-' + eventData && eventData.event
  //     ? eventData.event._def.extendedProps.data.id.substring(0, 20)
  //     : ''
  // );

  const baseUrl = React.useMemo(() => {
    return props.system.getNotificationSystem().getBaseUrlForDatagrid();
  }, [props]);

  const redirectToNotification = (not: any) => {
    if (not) {
      // let redirect = routes.calendar + '?' + not.not_entidad + '=' + not.not_entidad_id;
      // navigate(redirect);
      if (not.not_entidad == 'Actividad') {
        getActivityFromNotificacion(not.not_entidad_id);
      }
    }
  };

  const getNotificationsList = async () => {
    let notifications: Notification[] | any = await props.system
        .getNotificationSystem()
        .getAllNotifications();
    if (notifications) {
      setNotificationsList(notifications);
      setNotificationsFilteredList(notifications);
    }
  };

  const toggleIsCalloutVisible = () => {
    setIsCalloutVisible(!isCalloutVisible);
  };

  useEffect(() => {
    getNotificationsList();
  }, []);

  useEffect(() => {
    onFilterChange();
  }, [businnesFilter, textFilter, activeFilter, readFilter, dateFilter, fromDate]);

  const onFilterChange = () => {
    if (notificationsFilteredList && notificationsList) {
      let newNotifications: Notification[] = [];
      notificationsList.forEach((notification: Notification) => {
        const notificationDate = notification.not_fecha.split(' ');
        var splitDate = notificationDate[0].split('/');
        var newDate = splitDate[1] + '/' + splitDate[0] + '/' + splitDate[2];
        const jsDate = new Date(newDate);
        const luxonDate = DateTime.fromJSDate(jsDate);
        if (
            notification.not_asunto.toUpperCase().includes(businnesFilter.toUpperCase()) &&
            notification.not_texto.toUpperCase().includes(textFilter.toUpperCase()) &&
            (readFilter == null || notification.not_leido === readFilter) &&
            (activeFilter == null || notification.not_activo === activeFilter) &&
            (fromDate == null || luxonDate > fromDate)
        ) {
          newNotifications.push(notification);
        }
      });

      setNotificationsFilteredList(newNotifications);
    }
  };

  function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    return items
        .slice(0)
        .sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
  }

  const dragOptions = {
    moveMenuItemText: t('Move'),
    closeMenuItemText: t('Close'),
    menu: ContextualMenu,
  };

  const modalProps = {
    isBlocking: true,
    // styles: modalPropsStyles,
    dragOptions: dragOptions,
  };

  const dialogContentProps = React.useMemo(() => {
    return {
      type: DialogType.normal,
      title: t('Notificacion'),
    };
  }, [props]);

  const onReadAllClick = () => {
    setSelectedNotifications(notificationsList);
    // setRefresh(!refresh);
  };

  const onUnreadClick = () => {
    if (selectedNotifications) {
      let idList: string[] = [];
      selectedNotifications.forEach((not: Notification) => {
        idList.push(not.not_id);
      });
      props.system.getNotificationSystem().markAsUnreadList(idList);
      props.courier.messageSuccess(t('Marcado como no leído de manera exitosa'));
    }
    setTimeout(getNotificationsList, 1000);
    // setRefresh(!refresh);
  };

  const onDeleteClick = () => {
    if (selectedNotifications) {
      // selectedNotifications.forEach((notification: Notification) => {
      //   props.system.getNotificationSystem().delete(notification.not_id);
      // });
      let idList: string[] = [];
      selectedNotifications.forEach((not: Notification) => {
        idList.push(not.not_id);
      });
      props.system.getNotificationSystem().deleteList(idList);
      props.courier.messageSuccess(t('Eliminado de manera exitosa'));
      setSelectedNotifications([]);
    }
    setTimeout(getNotificationsList, 1000);
    // setRefresh(!refresh);
  };

  const onReadClick = (id: any) => {
    if (selectedNotifications) {
      // selectedNotifications.forEach((notification: Notification) => {
      //   props.system.getNotificationSystem().markAsRead(notification.not_id);
      // });
      if (!id) {
        let idList: string[] = [];
        selectedNotifications.forEach((not: Notification) => {
          idList.push(not.not_id);
        });
        props.system.getNotificationSystem().markAsReadList(idList);
      } else {
        props.system.getNotificationSystem().markAsRead(id);
      }
      props.courier.messageSuccess(t('Marcado como leído de manera exitosa'));
    }
    setTimeout(getNotificationsList, 1000);
    // setRefresh(!refresh);
  };

  // useEffect(() => {
  //   getNotificationsList();
  // }, [refresh]);

  const itemsMenuCalendar = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'readAll',
          text: t('Seleccionar todos'),
          iconProps: { iconName: 'BuildQueueNew', color: DefaultPalette.themeLighterAlt },
          buttonStyles: buttonStylesForProjects,
          onClick: () => onReadAllClick(),
        },
        {
          key: 'read',
          text: t('Marcar como leído'),
          iconProps: { iconName: 'Accept', color: DefaultPalette.themeLighterAlt },
          buttonStyles: buttonStylesForProjects,
          onClick: () => onReadClick(null),
        },
        {
          key: 'unread',
          text: t('Marcar como no leído'),
          iconProps: { iconName: 'BoxCheckmarkSolid', color: DefaultPalette.themeLighterAlt },
          buttonStyles: buttonStylesForProjects,
          onClick: () => onUnreadClick(),
        },
        {
          key: 'delete',
          text: t('Eliminar'),
          iconProps: { iconName: 'Delete', color: DefaultPalette.themeLighterAlt },
          buttonStyles: buttonStylesForProjects,
          onClick: () => onDeleteClick(),
        },
        {
          key: 'see',
          text: t('Ver'),
          iconProps: { iconName: 'Eye', color: DefaultPalette.themeLighterAlt },
          buttonStyles: buttonStylesForProjects,
          // disabled: selectedNotifications.length === 1 ? false : true,
          onClick: () =>
              selectedNotifications.length === 1 ? redirectToNotification(selectedNotifications[0]) : null,
        },
      ],
      [notificationsFilteredList, notificationsList, selectedNotifications]
  );

  const onActiveFilterChange = (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
    checked ? setActiveFilter('1') : setActiveFilter(null);
  };

  const onReadFilterChange = (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
    checked ? setReadFilter(false) : setReadFilter(null);
  };

  const onChangeDate = (date: Date | null | undefined) => {
    if (date) {
      setFromDate(DateTime.fromJSDate(date));
      // props.onChangeFromDate(DateTime.fromJSDate(date));
      // setShouldRefresh(!shouldRefresh);
      // if (props.onChangeFromDate) props.onChangeFromDate(DateTime.fromJSDate(date));
    }
  };

  const getNotificationsFromTable = (notifications: Notification[] | any) => {
    setSelectedNotifications(notifications);
  };

  const getActivityFromNotificacion = async (act_id: string) => {
    props.changeBlockUI(true);
    try {
      const act = await props.system.getActivitySystem().getActivity(act_id);
      setActivity(act);
      setShowDialog(true);
    } catch (error) {
      props.courier.messageError(error);
    } finally {
      props.changeBlockUI(false);
    }
  };

  const collaboratorActivity = React.useMemo(
      () => activity?.getCollaboratorActivityList().find((colact) => colact.referencesUser(props.user)),
      [activity, props.user]
  );

  const pendingTag = React.useMemo(
      () => !!collaboratorActivity && !collaboratorActivity.getChecked(),
      [collaboratorActivity]
  );

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={itemsMenuCalendar}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="standard"
      >
        <div className="padding-list">
          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row row-flex-calendar">
              <div className={'ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl2 ms-xxl2 filter-menu'}>
                <div>
                  <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-md6 ms-hiddenXlUp">
                        <Text block variant="medium">
                          {t('Filtros')}
                        </Text>
                      </div>
                      {/* <div className="ms-Grid-col ms-md6 ms-xl12 ms-xxl12"></div> */}
                    </div>

                    <div className="ms-Grid-row" dir="ltr">
                      <div className="ms-Grid-col ms-xxl12" key={'index'}>
                        <DatePicker
                            strings={getLocalizedStrings()}
                            label={t('Posterior a')}
                            className="filter-textfield-list"
                            formatDate={DateCustom.formatDateMMYYYY}
                            value={fromDate ? fromDate.toJSDate() : undefined}
                            onSelectDate={(e) => onChangeDate(e)}
                        />
                      </div>{' '}
                      <div className="ms-Grid-col ms-xxl12" key={'index'}>
                        <TextField
                            label={t('Asunto')}
                            autoComplete={'off'}
                            onChange={(event) => setBusinessFilter(event.currentTarget.value)}
                            className="filter-textfield-list"
                        />
                      </div>{' '}
                      <div className="ms-Grid-col ms-xxl12" key={'index'}>
                        <TextField
                            label={t('Notificacion')}
                            autoComplete={'off'}
                            onChange={(event) => setTextFilter(event.currentTarget.value)}
                            className="filter-textfield-list"
                        />
                      </div>{' '}
                      <div className="ms-Grid-col ms-xxl12" key={'index'}>
                        <Toggle label={t('Activos')} onChange={onActiveFilterChange} />
                      </div>{' '}
                      <div className="ms-Grid-col ms-xxl12" key={'index'}>
                        <Toggle label={t('No Leídos')} onChange={onReadFilterChange} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* {announcedMessage ? <Announced message={announcedMessage} /> : undefined} */}
              <div className={'ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl10 ms-xxl10'}>
                <div className="ms-Grid" dir="ltr">
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm10 ">
                      <Text block variant="large" className="text-bold title-filter-wrapper">
                        {t('Notificaciones')}
                      </Text>
                    </div>
                  </div>
                </div>
                <NotificationsTable
                    notificationsList={notificationsList}
                    notificationsFilteredList={notificationsFilteredList}
                    getNotificationsFromTable={getNotificationsFromTable}
                    toggleIsCalloutVisible={redirectToNotification}
                    navigate={navigate}
                    allSelected={selectedNotifications.length === notificationsList.length ? true : null}
                    noneSelected={selectedNotifications.length === 0 ? true : null}
                    onReadClick={onReadClick}
                    getActivityFromNotificacion={getActivityFromNotificacion}
                    baseUrl={baseUrl}
                />
              </div>
            </div>
          </div>
        </div>

        {activity && (
            <CalendarEventCallout
                periodsBlocked={props.periodsBlocked}
                system={props.system}
                projects={projects ? projects : []}
                user={props.user}
                eventData={null}
                targetId={'forNotif'}
                labelId={'forNotif'}
                descriptionId={'forNotif'}
                toggleIsCalloutVisible={() => {
                  if (pendingTag) setShowDialog(!showDialog);
                  setActivity(undefined);
                }}
                isCalloutVisible={pendingTag ? showDialog : false}
                commonContext={commonContext}
                getEvents={() => {
                  getNotificationsList();
                  setShowDialog(false);
                }}
                courier={props.courier}
                onlyShowDialogs={!pendingTag}
                activityProvided={activity}
                toggleShowDialog={() => {
                  if (!pendingTag) setShowDialog(!showDialog);
                  setActivity(undefined);
                }}
                showDialog={!pendingTag ? true : false}
                changeBlockUI={props.changeBlockUI}
                config={props.config}
                hideMenuOptions={true}
            />
        )}
      </ViewWrapperWithCommandBar>
  );
};
