import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Nav, INavStyles, INavLinkGroup, INavLink } from '@fluentui/react/lib/Nav';
import { Text, Stack, IStackStyles } from '@fluentui/react';
import { User } from '../../system/User';
import { APP_VERSION, DISABLE_MMI_OPTION, LOGO_PATROCINADOR, routes } from '../../common/constants';
import { t } from 'i18next';

const KEY_HOME = 'Home';
const KEY_TUTORIAL = 'Tutorial';
const KEY_CALENDAR = 'Calendar';
const KEY_PROJECTS = 'Projects';
const KEY_ACTIVITIES = 'Activities';
const KEY_COMPANYTREE = 'CompanyTree';
const KEY_DOCUMENTS = 'Documents';
const KEY_MAILS = 'Mails';
const KEY_PANEL = 'Panel';
const KEY_REPORTING = 'Reporting';
const KEY_REPORTING_COMPANY = 'ReportingCompany';
const KEY_REPORTING_SECTOR = 'ReportingSector';
const KEY_REPORTING_PROJECT = 'ReportingProject';
const KEY_REPORTING_BILLABLE_DISTRIBUTION = 'ReportingBillableDistribution';
const KEY_REPORTING_DOCS_PROJECT = 'ReportingDocsProject';
const KEY_REPORTING_PRELIQUIDATION = 'ReportingPreliquidation';
const KEY_DIRECT_LIQUIDATION = 'DirectLiquidation';
const KEY_SETTLEMENTS = 'Settlements';
const KEY_TEMPLATES = 'CostPerHour';

const KEY_COLLABORATORS = 'Collaborators';
const KEY_DEFENSEFILES = 'DefenseFiles';
const KEY_DEFENSEFILE = 'DefenseFile';
const KEY_MASSIVEDEFENSE = 'MassiveDefense';
const KEY_ADMIN = 'Admin';
const KEY_IMPORT_ACTIVITIES_EXCEL = 'ImportActivitiesExcel';
const KEY_LICENSES = 'Licenses';
const KEY_PERIODS_BLOCKED = 'PeriodsBlock';

const navLinkGroups: INavLinkGroup[] = [
  {
    links: [
      {
        name: t('Inicio App'),
        url: '/',
        key: KEY_HOME,
        icon: 'Home',
      },
      {
        name: t('Ayuda'),
        url: routes.help,
        key: KEY_TUTORIAL,
        icon: 'Lightbulb',
      },
      {
        name: t('Calendario'),
        url: routes.calendar,
        key: KEY_CALENDAR,
        icon: 'Calendar',
      },
      {
        name: t('Proyectos'),
        icon: 'Teamwork',
        url: routes.project_list,
        key: KEY_PROJECTS,
      },
      {
        name: t('Actividades'),
        icon: 'AccountActivity',
        url: routes.activity_list,
        key: KEY_ACTIVITIES,
      },
      {
        name: t('Documentos'),
        icon: 'TextDocument',
        url: routes.documents,
        key: KEY_DOCUMENTS,
      },

      {
        name: t('Panel'),
        icon: 'BIDashboard',
        url: routes.dashboard_own,
        key: KEY_PANEL,
      },
      {
        name: t('Reportes'),
        icon: 'Chart',
        url: '#',
        key: KEY_REPORTING,
        className: 'app-nav-reportes',
        automationId: 'app-nav-reportes',
        iconProps: { className: 'iconButtonAppNavClass', iconName: 'Chart' },
        links: [
          {
            name: t('Distribución facturable'),
            url: routes.reportsBillableDistribution,
            key: KEY_REPORTING_BILLABLE_DISTRIBUTION,
            icon: 'CRMReport',
            className: 'app-nav-reportes',
          },
          {
            name: t('Horas por empresa'),
            url: routes.reportsCompany,
            key: KEY_REPORTING_COMPANY,
            icon: 'CRMReport',
            className: 'app-nav-reportes',
          },
          {
            name: t('Horas por sector'),
            url: routes.reportsSector,
            key: KEY_REPORTING_SECTOR,
            icon: 'CRMReport',
            className: 'app-nav-reportes',
          },
          {
            name: t('Horas por proyecto'),
            url: routes.reportsProject,
            key: KEY_REPORTING_PROJECT,
            icon: 'CRMReport',
            className: 'app-nav-reportes',
          }
          // {
          //   name: t('Docs oblig. por proy.'),
          //   url: routes.reportsProjectDocs,
          //   key: KEY_REPORTING_DOCS_PROJECT,
          //   icon: 'CRMReport',
          //   className: 'app-nav-reportes',
          // },
        ],
      },
      {
        name: t('Liquidaciones'),
        icon: 'Chart',
        url: '#',
        key: KEY_SETTLEMENTS,
        className: 'app-nav-reportes',
        automationId: 'app-nav-reportes',
        iconProps: { className: 'iconButtonAppNavClass', iconName: 'TableComputed' },
        links: [
          {
            name: t('Liquidación indirecta'),
            url: routes.settlement_list,
            key: KEY_SETTLEMENTS,
            icon: 'TableComputed',
            disabled: true,
          },
          {
            name: t('Liquidación directa'),
            url: routes.direct_settlement_list,
            key: KEY_DIRECT_LIQUIDATION,
            icon: 'TableComputed',
          },
        ],
      },
      {
        name: t('Colaboradores'),
        url: routes.collaborator_list,
        icon: 'ReminderPerson',
        key: KEY_COLLABORATORS,
      },
      {
        name: t('Organigrama'),
        icon: 'Backlog',
        url: routes.company_tree,
        key: KEY_COMPANYTREE,
        // disabled: true,
      },

      {
        name: t('Defensa'),
        url: '#',
        icon: 'Shield',
        iconProps: { className: 'iconButtonAppNavClass', iconName: 'Shield' },
        key: KEY_DEFENSEFILES,
        disabled: false,
        links: [
          {
            name: t('Archivo de defensa'),
            url: routes.defense_file,
            key: KEY_DEFENSEFILE,
            icon: 'Shield',
            disabled: false,
          },
          {
            name: t('Defensa Masiva'),
            url: routes.massive_defense_list,
            key: KEY_MASSIVEDEFENSE,
            icon: 'ShieldSolid',
            disabled: false,
          },
        ],
      },
      {
        name: t('Importar act. Excel'),
        url: routes.activity_import,
        icon: 'ExcelLogo',
        key: KEY_IMPORT_ACTIVITIES_EXCEL,
      },
      {
        name: t('Licencias'),
        url: routes.licenses,
        icon: 'EventDateMissed12',
        key: KEY_LICENSES,
      },
      {
        name: t('Bloqueo de carga'),
        url: routes.bloqueo_periodos,
        icon: 'RemoveEvent',
        key: KEY_PERIODS_BLOCKED,
      },

      // {
      //   name: t('Mails'),
      //   icon: 'Mail',
      //   url: routes.mails,
      //   key: KEY_MAILS,
      //   disabled: DISABLE_MMI_OPTION,
      // },
      {
        name: t('Costo por hora'),
        icon: 'AccountManagement',
        url: routes.cost_per_hour_list,
        key: KEY_TEMPLATES,
      },
    ],
  },
];

interface AppNavProps {
  onLinkClicked?: () => void;
  className?: string;
  styles?: Partial<INavStyles>;
  user: User;
}

const optionsAvailableForStandardUser = [
  KEY_HOME,
  KEY_TUTORIAL,
  KEY_CALENDAR,
  KEY_PROJECTS,
  KEY_ACTIVITIES,
  KEY_PANEL,
  KEY_IMPORT_ACTIVITIES_EXCEL,
  KEY_LICENSES,
];

const optionsAvailableForDirective = [
  ...optionsAvailableForStandardUser,
  KEY_DOCUMENTS,
  KEY_REPORTING,
  KEY_REPORTING_COMPANY,
  KEY_REPORTING_SECTOR,
  KEY_REPORTING_PRELIQUIDATION,
  KEY_COLLABORATORS,
  KEY_COMPANYTREE,
];

const optionsAvailableForPowerUser = [
  ...optionsAvailableForDirective,
  KEY_MAILS,
  KEY_SETTLEMENTS,
  KEY_DEFENSEFILES,
  KEY_ADMIN,
  KEY_PERIODS_BLOCKED,
  KEY_TEMPLATES,
];

const AppNav: React.FC<AppNavProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const filterOptionsByUser = () => {
    let optionsForUser: string[] = [];

    if (props.user.getProfile().isStandardUser()) optionsForUser = optionsAvailableForStandardUser;
    if (props.user.getProfile().isDirectiveUser()) optionsForUser = optionsAvailableForDirective;
    if (props.user.getProfile().isPowerUser()) optionsForUser = optionsAvailableForPowerUser;
    if (props.user.getProfile().isAdminUser()) optionsForUser = optionsAvailableForPowerUser;

    let navLinkGroupsByUser: INavLinkGroup[] = [
      {
        links: [],
      },
    ];

    navLinkGroups[0].links.forEach((link) => {
      if (optionsForUser.includes(link.key!)) {
        navLinkGroupsByUser[0].links.push(link);
      }
    });

    return navLinkGroupsByUser;
  };

  const optionsSidebar = React.useMemo(() => {
    if (props.user) {
      return filterOptionsByUser();
    }
    return [];
  }, [props.user]);

  const selectedKey = React.useMemo(() => {
    const items = optionsSidebar.reduce<INavLink[]>((prev, navItem) => prev.concat(navItem.links), []);
    const index = items.findIndex((item) =>
        !item.links
            ? item.url.startsWith(location.pathname)
            : item.links.some((link) => link.url.startsWith(location.pathname))
    );

    const childIndex = items[index >= 0 ? index : 0].links
        ? items[index].links!.findIndex((link) => link.url.startsWith(location.pathname))
        : 0;

    return items[index >= 0 ? index : 0].links
        ? items[index >= 0 ? index : 0].links![childIndex].key
        : items[index >= 0 ? index : 0].key;
  }, [location.pathname, optionsSidebar]);

  const onLinkClick = (ev?: React.MouseEvent<HTMLElement, MouseEvent>, item?: INavLink) => {
    ev?.preventDefault();
    if (item) navigate(item.url);
    if (props.onLinkClicked) props.onLinkClicked();
  };

  const stackStyles: IStackStyles = {
    root: {
      height: '100%',
    },
  };

  return (
      <Stack verticalFill={true}>
        <Stack.Item grow={3} styles={stackStyles} className={'nav-container'}>
          <Nav
              selectedKey={selectedKey}
              ariaLabel="Barra de navegación"
              styles={props.styles}
              groups={optionsSidebar}
              className={props.className + ' iconized-menu '}
              onLinkClick={onLinkClick}
          />
        </Stack.Item>
        {/* <Stack.Item grow={1} style={{ textAlign: 'center', paddingTop: '10px' }} className={'hide-sidebar-info collab-info-title'}>
        <Text className="mt-2" variant="small">
          {t('En colaboración con')}
        </Text>
      </Stack.Item> */}
        {LOGO_PATROCINADOR ? (
            <Stack.Item grow={1} style={{ textAlign: 'center' }} className={'collab-info'}>
              <img
                  className="collab-logo mt-1 mb-1"
                  src={LOGO_PATROCINADOR}
                  alt="logo"
                  title={t('En colaboración con')}
              />
            </Stack.Item>
        ) : null}
        <Stack.Item style={{ textAlign: 'center' }} className={'hide-sidebar-info copyright-info'}>
          <Text className="mt-1" variant="small" title={`Version ${APP_VERSION}`}>
            {t('Copyright @2023')} &nbsp;
          </Text>
        </Stack.Item>
        {/* <Stack.Item style={{ textAlign: 'center' }} className={'version-info'}>
        <Text className="mt-2" variant="small">
          {APP_VERSION}
        </Text>
      </Stack.Item> */}
      </Stack>
  );
};

export default AppNav;
