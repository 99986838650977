import React, { useState } from 'react';
import {
  Facepile,
  Icon,
  IDropdownOption,
  IFacepilePersona,
  Persona,
  PersonaSize,
  DocumentCardActivity,
  IDocumentCardActivityPerson,
  Button,
  fontFace,
} from '@fluentui/react';
import { GRID_MODE } from '../../../common/list-utils';
import { DateCustom } from '../../../lib/DateCustom';
import { DetailsListView, EntityGridListProps } from '../../Common/DetailsList/DetailListView';
import i18next, { t } from 'i18next';
import "./collaboratorCreate.css";
import CollaboratorCreate from './collaboratorCreate';

export const CollaboratorGridList = (props: EntityGridListProps) => {
  const [viewCreate,setViewCreate] = useState<React.SetStateAction<number>>(0);
  const [toastExit,setToastExit] = useState<boolean>(false);
  const formatEquipo = (item: any) => {
    let equipo = (item.col_nombre + ' ' + item.col_apellido).split(' | ');

    var getInitials = function () {
      let initials = item.col_iniciales;
      return initials;
    };
    let equipoPersonas: IFacepilePersona[] = equipo.map((persona: string) => {
      return {
        personaName: persona,
        imageInitials: getInitials(),
      };
    });
    
    return (
        <Facepile
            personas={equipoPersonas}
            personaSize={10}
            maxDisplayablePersonas={6}
            overflowButtonProps={{ ariaLabel: 'Ver más' }}
            overflowButtonType={1}
            onRenderPersona={(props) => (
                <Persona {...props} size={PersonaSize.size24} onRenderPrimaryText={() => <></>}></Persona>
            )}
        />
    );
  };

  const columns = [
    {
      fieldName: 'col_iniciales',
      name: '',
      _onRender: (item: any) => formatEquipo(item),
      onRenderPersona: () => <></>,
      maxWidth: 40,
    },
    {
      fieldName:'col_id_sap',
      name:t('Codigo.SAP')
    },
    {
      fieldName: 'col_nombre',
      name: t('Nombre'),
    },
    {
      fieldName: 'col_apellido',
      name: t('Apellido'),
    },
    {
      fieldName: 'sector',
      name: t('Jefatura'),
    },  
      {
      fieldName: 'cargo_nombre',
      name: t('Cargo'),
    },
    {
      fieldName: 'col_email',
      name: t('Mail'),
    },
    {
      fieldName: 'col_fecha_alta',
      name: t('Fecha de ingreso'),
    },
    {
      fieldName: 'col_activo',
      name: t('Estado'),
      _onRender: (item: any) => item.col_activo == 1 ? "Activo ":"Inactivo" ,
    },
    {
      fieldName: 'col_fecha_baja',
      name: t('Fecha baja'),
    },
    {
      fieldName: 'col_actividad_facturable',
      name: t('Proyecto fijo'),
      _onRender: (item: any) => item.cnih_porcentaje_excento === null ? "no ":"si" ,
    },
    {
      fieldName: 'cnih_porcentaje_excento',
      name: t('PF-% participación'),
      _onRender: (item: any) =>item.cnih_porcentaje_excento? item.cnih_porcentaje_excento.split(".")[0]+"%":null ,
    },
    {
      fieldName: 'cnih_fecha_alta',
      name: t('PF-ingreso'),
    },
    {
      fieldName: 'cnih_fecha_baja',
      name: t('PF-fin'),
    },
    {
      fieldName: 'us_perid',
      name: t('Tipo de usuario'),
      _onRender: (item: any) => item.us_perid === "00000005"? 
      "User":item.us_perid === "00000010" ? "Directivo":item.us_perid === "00000006" ? "Power User":"Admin User",
    },
  ];

  return (
      <>
      {viewCreate === 0?
      <div> 
         {toastExit && 
         <div className="toast_container_collaborator">
            <div className="toast_message_collaborator">
               <Icon iconName='Completed'  className='icon_toast_collaborator'/>
                 Se agregó al colaborador exitosamente.
           </div>
            <Icon iconName='Clear' onClick={()=>setToastExit(false)}/>
         </div>}
        <div className='collaborator_create_hader_buton'>
         <Button onClick={()=>setViewCreate(1)}  className='collaborator_create_bt' > <Icon iconName='add' className='collaborator_create_icon'/> <p> Nuevo</p> </Button>
        </div>
 
       <DetailsListView
            keyList={'colaboradores'}
            onClickCardMode={() => props.onClickCardMode()}
            onClickGridMode={() => props.onClickGridMode()}
            mode={props.mode}
            title={t('Colaboradores')}
            onItemClick={props.onItemClick}
            cols={columns}
            isGridList={true}
            listFilters={[]}
            quickFilters={props.quickFilters}
            baseUrl={props.baseUrl}
            changeBlockUI={props.changeBlockUI}
            courier={props.courier}
        ></DetailsListView> 
      </div>
      :
      <CollaboratorCreate setviewcreate={setViewCreate} setToastExit={setToastExit} system={props.system} />
      } 
      </>
  );
};
